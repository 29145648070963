import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";
import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { AuthContext } from "../../Context/authContext";
import iclmLogo from "./assets/iclmLogo.jpeg";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: null,
            companyAddress: null,
            trainingAddress: null,
            gstType: 1,
            billId: null,
            id: 1,
            gstNo: null,
            date: moment(new Date()).format("YYYY-MM-DD"),
            contactName: null,
            mobileNo: null,
            emailId: null,
            sub: null,
            transportationCharges: 0,
            total: null,
            gst: null,
            grandTotal: null,
            invoiceId: null,
            itemName: null,
            description: null,
            sac: null,
            qty: null,
            rate: null,
            uom: null,
            amount: null,
            addedItems: [],
            sgst: 0,
            cgst: 0,
            igst: 0,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    deleteItem = (index) => {
        // let itemList = this.state.itemList;
        let itemList = this.state.addedItems;

        // update total & balance
        let total = this.state.total - itemList[index]["amount"];
        let balance = total + Number(this.state.advance);
        this.setState({ total: total }, this.calculateTaxes);
        this.setState({ balance: balance });

        // remove element
        // let updatedList = itemList.filter((item, _index) => {
        //     if (index !== _index) return item;
        // });
        // this.setState({ itemList: updatedList });
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ addedItems: updatedList });
    };

    getLatestId = () => {
        let url = BASE_URL;
        const query = `SELECT id FROM invoiceMaster ORDER BY id DESC LIMIT 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("latest id data: ", res.data);
                this.setState({
                    billId: (res.data[0] != null ? res.data[0]["id"] : 0) + 1,
                });
            })
            .catch((err) => {
                console.log("latest id data fetch error: ", err);
            });
    };

    calculateTaxes = () => {
        const total = this.state.total;

        this.setState(
            {
                cgst: Number((total / 100) * 9).toFixed(2),
                sgst: Number((total / 100) * 9).toFixed(2),
                igst: Number((total / 100) * 18).toFixed(2),
            },
            this.calculateGrandTotal
        );
        console.log(total);
    };

    calculateGrandTotal = () => {
        let grandTotal;
        if (this.state.gstType === 1) {
            grandTotal =
                Number(this.state.total) +
                Number(this.state.cgst) +
                Number(this.state.sgst);
        } else {
            grandTotal = Number(this.state.total) + Number(this.state.igst);
        }
        this.setState({ grandTotal: grandTotal.toFixed(2) });
    };

    addItems = () => {
        if (!this.state.itemName || !this.state.rate) return;
        // let items = this.state.itemList;
        let items = this.state.addedItems;
        const ifExists = items.find(
            (item) => item.itemName === this.state.itemName
        );
        if (ifExists) {
            items = items.map((item) => {
                if (item.itemName === this.state.itemName) {
                    return {
                        id: this.state.id,
                        itemName: this.state.itemName,
                        description: this.state.description,
                        qty: this.state.qty,
                        rate: this.state.rate,
                        sac: this.state.sac,
                        uom: this.state.uom,
                        amount:
                            +item.amount + +this.state.rate * +this.state.qty,
                    };
                }
            });
        } else {
            items.push({
                id: this.state.id,
                itemName: this.state.itemName,
                description: this.state.description,
                qty: this.state.qty,
                rate: this.state.rate,
                sac: this.state.sac,
                uom: this.state.uom,
                amount: this.state.rate * this.state.qty,
            });
        }
        // items.push({
        //     particular: this.state.particular,
        //     mark: this.state.mark,
        //     totalBoxes: this.state.totalBoxes,
        //     weightPerBox: this.state.weightPerBox,
        //     weight: this.state.weight,
        //     rate: this.state.rate,
        //     amount: this.state.amount,
        // });

        this.setState({ addedItems: items });
        console.log(this.state.addedItems);

        // update total & balance
        // let total = Number(this.state.total) + Number(this.state.amount);
        let total = Number(this.state.rate * this.state.qty);
        this.setState({ total: total }, this.calculateTaxes);
        let balance = total - Number(this.state.paid);
        this.setState({ balance: balance });
        // this.calculateTaxes();
    };

    //   handleClear = () => {
    //     return null;
    //   };

    insertBillList = () => {
        let url = BASE_URL;

        // 1.  insert into deliveryMemoList
        this.state.addedItems.map((item, index) => {
            const query = `INSERT INTO invoiceItems(invoiceId, itemName, description, sac, qty, rate, uom, amount, status) VALUES(
      ${this.state.billId},
      '${item.itemName}',
      '${item.description}',
      '${item.sac}',
      ${item.qty}, 
      ${item.rate}, 
      '${item.uom}', 
      ${item.amount},
      1
    )`;
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "insert invoiceItems successfull, index: ",
                        index
                    );
                })
                .catch((err) => {
                    console.log("failed to insert invoiceItems, error: ", err);
                });
        });
    };
    insertPaymentEntry = () => {
        let query = `INSERT INTO collection (date, invoiceNo, gstNo, customerName, address, billAmount, paid, pending) values(
            '${this.state.date}',
            ${this.state.billId},
            ${this.state.gstNo},
            '${this.state.companyName}',
            '${this.state.companyAddress}',
            ${this.state.grandTotal},
            0,
            ${this.state.grandTotal}
       )`;
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(BASE_URL, data)
            .then((res) => {})
            .catch((err) => {
                toast.error("Failed to Generate payment history ");
            });
    };

    saveBill = () => {
        const newDate = moment(new Date()).format("YYYY-MM-DD");
        let query;

        query = `INSERT INTO invoiceMaster (invoiceNo,gstType, date, companyName, trainingAddress, companyAddress, gstNo, contactName, mobileNo, emailId, sub, transportationCharges, total, gst, grandTotal,status) values(
        ${this.state.billId},
        ${this.state.gstType},
        ${this.state.date},
        '${this.state.billTo}', 
        '${this.state.trainingAddress}',
        '${this.state.companyAddress}',
        '${this.state.gstNo}',
        '${this.state.contactName}',
        ${this.state.mobileNo},
        '${this.state.emailId}',
        '${this.state.sub}',
        ${this.state.transportationCharges},
        ${this.state.total},
        '${this.state.gst}',
        ${this.state.grandTotal},
        1)`;
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(BASE_URL, data)
            .then((res) => {
                toast.success("Invoice generated successfully");
                this.setState({ billId: res.data.insertId }, () => {
                    this.insertBillList();
                    this.insertPaymentEntry();
                });
            })
            .catch((err) => {
                toast.error("Failed to Generate Bill ");
            });
    };

    handleSavePrint = (e) => {
        console.log("in handle save print");
        // 1. handle save
        this.handleSave();
    };

    componentDidMount() {
        this.getLatestId();
        // this.getIdPartyList();
        // this.getproductData();
        // this.fetchNewId();
    }

    render() {
        return (
            <form
                className="mb-5"
                style={{ margin: "10px" }}
                onSubmit={(e) => e.preventDefault()}
            >
                <div>
                    {/* Content Wrapper. Contains page content */}
                    <div className="">
                        {/* Main content */}
                        <div className="row">
                            <FormControl
                                style={{ width: "220px" }}
                                className="mr-2 mb-2 smt-0"
                            >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    options={
                                        this.state.partyList != null
                                            ? this.state.partyList.map(
                                                  (item) =>
                                                      item.id + ", " + item.name
                                              )
                                            : []
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Bill to"
                                            variant="outlined"
                                            size="small"
                                            value={this.state.billTo}
                                            onChange={(event) =>
                                                this.setState({
                                                    billTo: event.target.value,
                                                })
                                            }
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        if (value != null && value.length > 2) {
                                            this.setState({
                                                partyId: value.split(", ")[0],
                                                partyName: value.split(", ")[1],
                                                address:
                                                    this.state.partyList.find(
                                                        (party) =>
                                                            party.id ==
                                                            value.split(", ")[0]
                                                    )?.address,
                                            });
                                        } else {
                                            this.setState({
                                                partyId: null,
                                                partyName: "",
                                            });
                                        }
                                    }}
                                />
                            </FormControl>

                            <TextField
                                style={{ width: "220px" }}
                                id="Office At"
                                label="Office At"
                                variant="outlined"
                                className={"mr-2"}
                                value={this.state.companyAddress}
                                onChange={(e) =>
                                    this.setState({
                                        companyAddress: e.target.value,
                                    })
                                }
                                size="small"
                            />

                            <TextField
                                style={{ width: "220px" }}
                                id="trainingAddress"
                                label="Training Address"
                                variant="outlined"
                                className="mr-2"
                                value={this.state.trainingAddress || ""}
                                onChange={(e) =>
                                    this.setState({
                                        trainingAddress: e.target.value,
                                    })
                                }
                                disabled={!!this.state.partyId}
                                size="small"
                            />

                            <FormControl
                                style={{ width: "220px" }}
                                variant="outlined"
                                className="mr-2 "
                                size="small"
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Bill Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={(e) =>
                                        this.setState({
                                            gstType: e.target.value,
                                        })
                                    }
                                    name="gstType"
                                    value={this.state.gstType}
                                    size="small"
                                >
                                    <MenuItem value={1}>
                                        State Central GST
                                    </MenuItem>
                                    <MenuItem value={2}>IGST</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                style={{ width: "220px" }}
                                id="gstin"
                                label="GSTIN"
                                variant="outlined"
                                className="mr-2 "
                                value={this.state.gstNo}
                                onChange={(e) =>
                                    this.setState({ gstNo: e.target.value })
                                }
                                size="small"
                            />
                        </div>
                        <div>
                            <TextField
                                style={{ width: "220px" }}
                                id="mobileNo"
                                label="Mobile Number"
                                variant="outlined"
                                className="mr-2 mt-1"
                                value={this.state.mobileNo}
                                onChange={(e) =>
                                    this.setState({ mobileNo: e.target.value })
                                }
                                size="small"
                            />

                            <TextField
                                style={{ width: "220px" }}
                                id="emailId"
                                label="Email"
                                variant="outlined"
                                className="mr-2 mt-1"
                                value={this.state.emailId}
                                onChange={(e) =>
                                    this.setState({ emailId: e.target.value })
                                }
                                required="true"
                                size="small"
                                type="text"
                            />
                            <TextField
                                style={{ width: "220px" }}
                                id="subject"
                                label="Subject"
                                variant="outlined"
                                className="mr-2 mt-1"
                                value={this.state.sub}
                                onChange={(e) =>
                                    this.setState({ sub: e.target.value })
                                }
                                required="true"
                                size="small"
                                type="text"
                            />
                            <br />
                            <FormControl
                                style={{ width: "220px" }}
                                className="mr-2 mb-2 mt-1"
                            >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    options={
                                        this.state.partyList != null
                                            ? this.state.partyList.map(
                                                  (item) =>
                                                      item.id + ", " + item.name
                                              )
                                            : []
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Topic Name"
                                            variant="outlined"
                                            size="small"
                                            value={this.state.itemName}
                                            onChange={(event) =>
                                                this.setState({
                                                    itemName:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        if (value != null && value.length > 2) {
                                            this.setState({
                                                partyId: value.split(", ")[0],
                                                partyName: value.split(", ")[1],
                                                address:
                                                    this.state.partyList.find(
                                                        (party) =>
                                                            party.id ==
                                                            value.split(", ")[0]
                                                    )?.address,
                                            });
                                        } else {
                                            this.setState({
                                                partyId: null,
                                                partyName: "",
                                            });
                                        }
                                    }}
                                />
                            </FormControl>

                            <TextField
                                style={{ width: "220px" }}
                                id="description"
                                label="Description"
                                variant="outlined"
                                className={"mr-2 mt-1"}
                                value={this.state.description}
                                onChange={(e) =>
                                    this.setState({
                                        description: e.target.value,
                                    })
                                }
                                size="small"
                            />
                            <TextField
                                style={{ width: "220px" }}
                                id="sac"
                                label="SAC"
                                variant="outlined"
                                className="mr-2 mt-1"
                                value={this.state.sac}
                                onChange={(e) =>
                                    this.setState({ sac: e.target.value })
                                }
                                size="small"
                            />

                            <TextField
                                style={{ width: "220px" }}
                                id="qty"
                                label="Quantity"
                                variant="outlined"
                                className="mr-2 "
                                value={this.state.qty}
                                onChange={(e) =>
                                    this.setState({ qty: e.target.value })
                                }
                                size="small"
                            />
                            <TextField
                                style={{ width: "220px" }}
                                id="rate"
                                label="Rate"
                                variant="outlined"
                                className="mr-2 "
                                value={this.state.rate}
                                onChange={(e) =>
                                    this.setState({ rate: e.target.value })
                                }
                                size="small"
                            />
                            <TextField
                                style={{ width: "220px" }}
                                id="uom"
                                label="UOM"
                                variant="outlined"
                                className="mr-2 "
                                value={this.state.uom}
                                onChange={(e) =>
                                    this.setState({ uom: e.target.value })
                                }
                                size="small"
                            />

                            <Button
                                variant="contained"
                                className="btn btn-primary"
                                onClick={this.addItems}
                                disabled={
                                    !this.state.billTo ||
                                    !this.state.companyAddress ||
                                    !this.state.trainingAddress ||
                                    !this.state.gstType ||
                                    !this.state.gstNo ||
                                    !this.state.mobileNo ||
                                    !this.state.emailId ||
                                    !this.state.sub ||
                                    !this.state.itemName ||
                                    !this.state.description ||
                                    !this.state.sac ||
                                    !this.state.qty ||
                                    !this.state.rate ||
                                    !this.state.uom
                                }
                            >
                                Add
                            </Button>

                            <hr />
                        </div>
                        <div
                            className="mt-1 p-2 measure"
                            ref={(el) => (this.printComponentRef = el)}
                        >
                            <Row>
                                <Col md={8} className="mx-auto">
                                    <Card
                                        className="mt-2 p-0"
                                        style={{
                                            height: "auto",
                                            border: "1px solid black",
                                        }}
                                    >
                                        <Card.Header>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h5 className="text-center pb-0 mb-0">
                                                        <img
                                                            height={100}
                                                            src={iclmLogo}
                                                        ></img>
                                                        <h6>
                                                            Institute of
                                                            Construction
                                                            Equipments and
                                                            Lifting Machines
                                                        </h6>
                                                    </h5>
                                                    <hr />
                                                </div>
                                                <div className="col-12">
                                                    <h6 className="text-center">
                                                        Office:- 607/R Square,
                                                        Veena Nagar, Opposite
                                                        kamgar Hospital, LBS
                                                        Road,
                                                        <br /> Mulund West- 400
                                                        080
                                                        <br />{" "}
                                                        Email:iclmconnect@gmail.com,
                                                        www.iclm.org.in, Mobile:
                                                        09321905149
                                                    </h6>
                                                </div>
                                            </div>
                                            <hr />

                                            <span
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    marginBottom: "-1em",
                                                }}
                                            >
                                                <p>
                                                    Invoice No.{" "}
                                                    <b>{this.state.billId}</b>
                                                </p>
                                                <p>
                                                    Date{" "}
                                                    <b>
                                                        {moment(
                                                            new Date()
                                                        ).format(
                                                            "D / M / YYYY"
                                                        )}
                                                    </b>
                                                </p>
                                            </span>
                                            <hr />
                                            <Card.Body
                                                className="pb-3 mb-0"
                                                style={{
                                                    border: "1px solid black",
                                                }}
                                            >
                                                <Row>
                                                    <Col md={6}>
                                                        <h6
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            Bill To :-{" "}
                                                            <b>
                                                                {
                                                                    this.state
                                                                        .billTo
                                                                }
                                                            </b>
                                                        </h6>
                                                    </Col>
                                                    <Col md={6}>
                                                        <h6
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            Training Add :-{" "}
                                                            <b>
                                                                {
                                                                    this.state
                                                                        .trainingAddress
                                                                }
                                                            </b>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <h6
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            Office At :-{" "}
                                                            <b>
                                                                {
                                                                    this.state
                                                                        .companyAddress
                                                                }
                                                            </b>
                                                        </h6>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <h6
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            GST NO :-{" "}
                                                            <b>
                                                                {
                                                                    this.state
                                                                        .gstNo
                                                                }
                                                            </b>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card.Header>

                                        <h5 className="text-center pb-0 mt-2">
                                            <b> TAX INVOICE</b>
                                        </h5>

                                        <Card.Header>
                                            <Card.Body
                                                className="pb-3 mb-0"
                                                style={{
                                                    border: "1px solid black",
                                                }}
                                            >
                                                <Row>
                                                    <Col md={6}>
                                                        <h6
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            Contact No :-{" "}
                                                            <b>
                                                                {
                                                                    this.state
                                                                        .mobileNo
                                                                }
                                                            </b>
                                                        </h6>
                                                    </Col>
                                                    <Col md={6}>
                                                        <h6
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            sub :-{" "}
                                                            <b>
                                                                {this.state.sub}
                                                            </b>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <h6
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            Email Id :-{" "}
                                                            <b>
                                                                {
                                                                    this.state
                                                                        .emailId
                                                                }
                                                            </b>
                                                        </h6>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card.Header>
                                        <Card.Body className="m-0 pt-0 mt-3">
                                            <Tbl
                                                striped
                                                bordered
                                                hover
                                                size="sm"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Sr.No</th>
                                                        <th>Description</th>
                                                        <th>Topic Name</th>
                                                        <th>SAC</th>
                                                        <th>QTY</th>
                                                        <th>Rate</th>
                                                        <th>UOM</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>

                                                {this.state.addedItems.length >
                                                0 ? (
                                                    <tbody>
                                                        {this.state.addedItems.map(
                                                            (item, id) => {
                                                                return (
                                                                    <tr
                                                                        key={id}
                                                                    >
                                                                        <td>
                                                                            {id +
                                                                                1}{" "}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.description
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.itemName
                                                                            }{" "}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.sac
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.qty
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.rate
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.uom
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.amount
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            className="d-print-none"
                                                                            align="center"
                                                                        >
                                                                            <button
                                                                                variant="contained"
                                                                                class="btn btn-danger"
                                                                                onClick={() =>
                                                                                    this.deleteItem(
                                                                                        id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                        <br></br>

                                                        <>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td colSpan="2">
                                                                    Transportation
                                                                    charges
                                                                </td>
                                                                <td></td>

                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .transportationCharges
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td colSpan="2">
                                                                    Total
                                                                </td>
                                                                <td></td>

                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .total
                                                                    }
                                                                </td>
                                                            </tr>

                                                            {this.state
                                                                .gstType ===
                                                            1 ? (
                                                                <>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td colSpan="2">
                                                                            SGST
                                                                            @
                                                                        </td>

                                                                        <td>
                                                                            9%
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .sgst
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td colSpan="2">
                                                                            CGST
                                                                            @
                                                                        </td>

                                                                        <td>
                                                                            9%
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .cgst
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td colSpan="2">
                                                                        IGST @
                                                                    </td>

                                                                    <td>18%</td>
                                                                    <td>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .igst
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td colSpan="4">
                                                                    {" "}
                                                                </td>
                                                                <td colSpan="2">
                                                                    Grand Total
                                                                </td>

                                                                <td></td>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .grandTotal
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="4">
                                                                    Total in
                                                                    words :{" "}
                                                                </td>

                                                                <td colSpan="2">
                                                                    Total
                                                                    (Rounded)
                                                                </td>

                                                                <td></td>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .grandTotal
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </>
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="8">
                                                                No items added
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </Tbl>
                                        </Card.Body>
                                        <Card.Body
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-9">
                                                    <h6
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        <b>
                                                            GST NO:
                                                            27AYTPS6119F1ZV
                                                        </b>
                                                        <br />
                                                        I. TAX PAN No. :
                                                        AYTPS6119F
                                                        <br />
                                                        Company Bank Details:
                                                        <br />
                                                        A/C Name: Institute of
                                                        Construction Equipments
                                                        and Lifting Machines
                                                        <br />
                                                        Axis Bank, A/C no:
                                                        '917020066522350 , IFSC
                                                        Code: UTIB0000772
                                                        <br />
                                                        I/We hereby certify that
                                                        my/our Registration
                                                        Certificate under the
                                                        Maharashtra Value Added
                                                        Tax Act 2002 is in force
                                                        on the date on which the
                                                        sale of the goods
                                                        specified in this tax
                                                        invoice is made by me/us
                                                        and that transaction of
                                                        the sale covered by this
                                                        tax invoice has been
                                                        effected by we/us and it
                                                        shall be counted for in
                                                        the turn over ofsales
                                                        while filling of return
                                                        and the due tax, if any
                                                        payable on thesales has
                                                        been paid or shall be
                                                        paid.
                                                    </h6>
                                                </div>
                                                <div className="col-3">
                                                    <h6
                                                        style={{
                                                            marginBottom: "7em",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        For Institute of
                                                        Construction Equipments
                                                        and Lifting Machines
                                                    </h6>
                                                    <h6
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        Authorised Signatory
                                                    </h6>
                                                </div>
                                            </div>
                                        </Card.Body>

                                        <div
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <h5>Terms Of Payment :</h5>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <h5 style={{ fontSize: "15px" }}>
                                                1) Payment should be made
                                                Immediately against receipt of
                                                invoice
                                            </h5>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <h5 style={{ fontSize: "15px" }}>
                                                2) If payment is not made within
                                                45 days GST credit will be
                                                reversed
                                            </h5>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <h5 style={{ fontSize: "15px" }}>
                                                3) Additional 21 % interest will
                                                be charged on non-payment of
                                                bill within 45 days
                                            </h5>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <h5 style={{ fontSize: "15px" }}>
                                                4) Invoice once accepted will
                                                not be entertained for any
                                                corrections
                                            </h5>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <h5 style={{ fontSize: "15px" }}>
                                                5) Customers are not entitled to
                                                claim GST credit against this
                                                bill until full amount not paid
                                                as mentioned in Total Amount
                                                column.
                                            </h5>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        {/* /.content */}
                    </div>
                    {/* /.content-wrapper */}
                </div>
                <div className="col-10">
                    <ReactToPrint content={() => this.printComponentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button
                                    onClick={handlePrint}
                                    className="btn btn-primary mt-2 mr-1"
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right" }}
                                    // disabled={
                                    //     (this.state.partyName ||
                                    //         this.state.newPartyName) &&
                                    //         this.state.address
                                    //         ? false
                                    //         : true
                                    // }
                                >
                                    Print
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Button
                        className="btn btn-primary mt-2 mr-1"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.saveBill}
                        // disabled={
                        //   this.state.partyName
                        //     ? false
                        //     : true
                        // }
                    >
                        Save bill
                    </Button>
                    <Button
                        className="btn btn-primary mt-2 mr-1 "
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleClear}
                    >
                        clear
                    </Button>
                </div>
            </form>
        );
    }
}
