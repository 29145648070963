import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class Outstanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Collections: null,
            newCollection: {
                CollectionId: null,
                invoiceNo: null,
                gstNo: null,
                customerName: null,
                address: null,
                billAmt: null,
                status: null,
            },

            currentCollection_id: null,
            currentCollection_invoiceNo: null,
            currentCollection_gstNo: null,
            currentCollection_customerName: null,
            currentCollection_address: null,
            currentCollection_billAmt: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeOrg(e) {
        let newCollection = this.state.newCollection;
        newCollection.invoiceNo = e.target.value;
        this.setState({ newCollection: newCollection });
    }
    onChangeOrg(e) {
        let newCollection = this.state.newCollection;
        newCollection.gstNo = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeOrg(e) {
        let newCollection = this.state.newCollection;
        newCollection.customerName = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeOrg(e) {
        let newCollection = this.state.newCollection;
        newCollection.address = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeOrg(e) {
        let newCollection = this.state.newCollection;
        newCollection.billAmt = e.target.value;
        this.setState({ newCollection: newCollection });
    }

  

    

    // onSubmit(e) {
    //     let url = BASE_URL;
    //     const query =
    //         'INSERT INTO trainerMaster (firstName, lastName, address, qualification, emailId, mobileNo) values("' +
    //         this.state.newTrainer.firstName +
    //         '","' +
    //         this.state.newTrainer.lastName +
    //         '","' +
    //         this.state.newTrainer.address +
    //         '","' +
    //         this.state.newTrainer.qualification +
    //         '", "' +
    //         this.state.newTrainer.email +
    //         '", "' +
    //         this.state.newTrainer.mobileNo +
    //         '") ';
    //     console.log(query);
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             toast("new trainer added!");
    //             setTimeout(this.refresh, 1000);
    //         })
    //         .catch((error) => {
    //             toast("Something went wrong");
    //         });

    //     this.setState({
    //         firstName: "",
    //         lastName: "",
    //         address: "",
    //         qualification: "",
    //         email: "",
    //         mobileNo: "",
    //     });
    // }

    // submitTrainerUpdate(e) {
    //     let url = BASE_URL;
    //     const query =
    //         'UPDATE trainerMaster SET firstName="' +
    //         this.state.currentTrainer_firstName +
    //         '",lastname="' +
    //         this.state.currentTrainer_lastName +
    //         '", emailId="' +
    //         this.state.currentTrainer_email +
    //         '",address="' +
    //         this.state.currentTrainer_address +
    //         '",qualification="' +
    //         this.state.currentTrainer_qualification +
    //         '", mobileNo="' +
    //         this.state.currentTrainer_mobileNo +
    //         '"  WHERE id=' +
    //         this.state.currentTrainer_id +
    //         "";

    //     console.log(query);

    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             toast("trainer updated successfully!");
    //             setTimeout(this.refresh, 1000);
    //         })
    //         .catch((error) => {
    //             toast("Something went wrong");
    //         });

    //     this.setState({
    //         firstName: "",
    //         lastName: "",
    //         qualification: "",
    //         email: "",
    //         mobileNo: "",
    //         address: "",
    //     });
    // }

    // submitTrainerDelete(id) {
    //     let url = BASE_URL;
    //     const query = `UPDATE trainerMaster SET status = -1 where id = ${id};`;

    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             toast("trainer deleted successfully!");
    //             setTimeout(this.refresh, 1000);
    //         })
    //         .catch((error) => {
    //             toast("Something went wrong");
    //         });

    //     this.setState({
    //         firstName: "",
    //         lastName: "",
    //         address: "",
    //         qualification: "",
    //         email: "",
    //         mobileNo: "",
    //     });
    // }

    // fetchTrainersData() {
    //     let url = BASE_URL;
    //     const query = `SELECT * FROM trainerMaster where status = 1;`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };

    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             this.setState({ trainers: res.data });
    //         })
    //         .catch((err) => {});
    // }

    // componentDidMount() {
    //     this.fetchTrainersData();
    // }
    componentDidUpdate() {
        $(document).ready(function () {
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderTrainersData() {
        const customers = this.state.customers;

        if (customers == null) {
            return null;
        }

        return customers.map((customer) => {
            return (
                <tr>
                    <td>{customer.id}</td>
                    <td>{customer.org}</td>
                    <td>{customer.projectName}</td>
                    <td>{customer.siteAddress}</td>
                    <td>{customer.client}</td>
                    <td>{customer.contactPerson}</td>
                    <td>{customer.category}</td>
                    <td>{customer.mobileNo}</td>
                    <td>{customer.emailId}</td>
                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState({
                                    currentcustomer_id: customer.id,
                                    currentcustomer_org: customer.org,
                                    currentcustomer_projectName: customer.projectName,
                                    currentcustomer_siteAddress: customer.siteAddress,
                                    currentcustomer_client: customer.client,
                                    currentcustomer_emailId: customer.emailId,
                                    currentcustomer_category: customer.category,
                                    currentcustomer_mobileNo: customer.mobileNo,
                                    viewModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={(e) => {
                                this.setState({
                                    currentcustomer_id: customer.id,
                                    currentcustomer_org: customer.org,
                                    currentcustomer_projectName: customer.projectName,
                                    currentcustomer_siteAddress: customer.siteAddress,
                                    currentcustomer_client: customer.client,
                                    currentcustomer_emailId: customer.emailId,
                                    currentcustomer_category: customer.category,
                                    currentcustomer_mobileNo: customer.mobileNo,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete trainer?"
                                    )
                                ) {
                                    this.submitTrainerDelete(customer.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    // renderViewModal() {
    //     return (
    //         <Modal
    //             show={this.state.viewModal}
    //             onHide={() => {
    //                 this.setState({ viewModal: false });
    //             }}
    //         >
    //             <Modal.Header closeButton>
    //                 <Modal.Title>View Customer</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>
    //             <form>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Org:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_org}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_org:
    //                                         e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Project Name:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_projectName}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_projectName: e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Site Address:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_siteAddress}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_siteAddress: e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                         />
    //                     </div>

    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Client:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_client}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_client:
    //                                         e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteAddress"
    //                             className="col-form-label"
    //                         >
    //                             Contact Person:
    //                         </label>
    //                         <textarea
    //                             className="form-control"
    //                             name="address"
    //                             value={this.state.currentcustomer_contactPerson}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_contactPerson: e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                             id="siteAddress"
    //                         />
    //                     </div>

    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteEngineers"
    //                             className="col-form-label"
    //                         >
    //                             Category:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="Engineers"
    //                             value={this.state.currentcustomer_category}
    //                             className="form-control"
    //                             id="siteEngineers"
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_category: e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteEngineers"
    //                             className="col-form-label"
    //                         >
    //                             Mobile No:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="Engineers"
    //                             value={this.state.currentcustomer_mobileNo}
    //                             className="form-control"
    //                             id="siteEngineers"
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_mobileNo: e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteEngineers"
    //                             className="col-form-label"
    //                         >
    //                             Email Id:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="Engineers"
    //                             value={this.state.currentcustomer_emailId}
    //                             className="form-control"
    //                             id="siteEngineers"
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_emailId: e.target.value,
    //                                 });
    //                             }}
    //                             readOnly
    //                         />
    //                     </div>
    //                 </form>
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button
    //                     variant="secondary"
    //                     onClick={() => {
    //                         this.setState({ viewModal: false });
    //                     }}
    //                 >
    //                     Close
    //                 </Button>
    //             </Modal.Footer>
    //         </Modal>
    //     );
    // }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    // renderUpdateModal() {
    //     return (
    //         <Modal
    //             show={this.state.updateModal}
    //             onHide={() => {
    //                 this.setState({ updateModal: false });
    //             }}
    //         >
    //             <Modal.Header closeButton>
    //                 <Modal.Title>Update Customer</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>
    //                 <form>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Org:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_org}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_org:
    //                                         e.target.value,
    //                                 });
    //                             }}
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Project Name:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_projectName}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_projectName: e.target.value,
    //                                 });
    //                             }}
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Site Address:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_siteAddress}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_siteAddress: e.target.value,
    //                                 });
    //                             }}
    //                         />
    //                     </div>

    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteName"
    //                             className="col-form-label"
    //                         >
    //                             Client:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="name"
    //                             className="form-control"
    //                             id="siteName"
    //                             value={this.state.currentcustomer_client}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_client:
    //                                         e.target.value,
    //                                 });
    //                             }}
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteAddress"
    //                             className="col-form-label"
    //                         >
    //                             Contact Person:
    //                         </label>
    //                         <textarea
    //                             className="form-control"
    //                             name="address"
    //                             value={this.state.currentcustomer_contactPerson}
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_contactPerson: e.target.value,
    //                                 });
    //                             }}
    //                             id="siteAddress"
    //                         />
    //                     </div>

    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteEngineers"
    //                             className="col-form-label"
    //                         >
    //                             Category:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="Engineers"
    //                             value={this.state.currentcustomer_category}
    //                             className="form-control"
    //                             id="siteEngineers"
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_category: e.target.value,
    //                                 });
    //                             }}
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteEngineers"
    //                             className="col-form-label"
    //                         >
    //                             Mobile No:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="Engineers"
    //                             value={this.state.currentcustomer_mobileNo}
    //                             className="form-control"
    //                             id="siteEngineers"
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_mobileNo: e.target.value,
    //                                 });
    //                             }}
    //                         />
    //                     </div>
    //                     <div className="form-group">
    //                         <label
    //                             htmlFor="siteEngineers"
    //                             className="col-form-label"
    //                         >
    //                             Email Id:
    //                         </label>
    //                         <input
    //                             type="text"
    //                             name="Engineers"
    //                             value={this.state.currentcustomer_emailId}
    //                             className="form-control"
    //                             id="siteEngineers"
    //                             onChange={(e) => {
    //                                 this.setState({
    //                                     currentcustomer_emailId: e.target.value,
    //                                 });
    //                             }}
    //                         />
    //                     </div>
    //                 </form>
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button
    //                     variant="secondary"
    //                     onClick={() => {
    //                         this.setState({ updateModal: false });
    //                     }}
    //                 >
    //                     Close
    //                 </Button>
    //                 <Button
    //                     variant="primary"
    //                     onClick={(e) => {
    //                         this.submitTrainerUpdate(e);
    //                         this.setState({ updateModal: false });
    //                     }}
    //                 >
    //                     Save Changes
    //                 </Button>
    //             </Modal.Footer>
    //         </Modal>
    //     );
    // }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-user-plus"></i>
                                                </span>
                                                &nbsp;Add New Collection
                                            </button>
                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New Collection
                                                                {/*  */}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Date:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeDate(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Invoice No:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeInvoiceMO(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        GST No:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeGstNo(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Client:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeClient(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Customer Name:
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeCustomerName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Address:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeAddress(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Bill Amt:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeBillAmt(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.onSubmit(
                                                                        e
                                                                    );
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for update  starts here*/}

                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Date{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                    Invoice No{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                    GST No.{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                    Customer Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                    Address{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                    Bill Amt{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                   
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderTrainersData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {/* {this.renderViewModal()}
                    {this.renderUpdateModal()} */}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
