import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: null,

            selectedContentFile: null,

            activeContentFilePath: null,
            newCourse: {
                moduleNo: null,
                moduleName: null,
                status: null,
            },

            moudleNoError: null,
            moduleNameError: null,

            currentCourse_id: null,
            currentCourse_moduleName: null,
            currentCourse_moduleNo: null,
            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeCourseModuleName(e) {
        let newCourse = this.state.newCourse;
        newCourse.moduleName = e.target.value;
        this.setState({ newCourse: newCourse });
    }

    onChangeCourseModuleNo(e) {
        let newCourse = this.state.newCourse;
        newCourse.moduleNo = e.target.value;
        this.setState({ newCourse: newCourse });
    }

    validate() {
        let status = true;
        if (this.state.newCourse.moduleName == null) {
            this.setState({ moduleNameError: "please fill up the field" });
            status = false;
        }
        // if ( this.state.newCourse.trainerName == null) {
        //     this.setState({ trainerNameError: "please fill up the field" });
        //     console.log(this.state.trainerNameError)
        //     status = false;
        // }
        if (this.state.newCourse.moduleNo == null) {
            this.setState({ moduleNoError: "please fill up the field" });
            status = false;
        }

        return status;
    }

    handleContentFileInputChange = (event) => {
        this.setState({
            selectedContentFile: event.target.files[0],
        });
    };

    submitFile(type) {
        const data = new FormData();

        data.append("fileToUpload", this.state.selectedContentFile);

        let url = "https://iclm.5techg.com/api/fileUpload/";

        axios.post(url, data).then((res) => {
            // then print response status
            this.setState({ activeContentFilePath: res.data }, this.onSubmit);
        });
    }

    onSubmit(e) {
        if (this.validate()) {
            let url = BASE_URL;
            const query =
                'INSERT INTO courses ( moduleNo, moduleName, contentFilePath) values("' +
                this.state.newCourse.moduleNo +
                '","' +
                this.state.newCourse.moduleName +
                '","' +
                this.state.activeContentFilePath +
                '") ';
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    toast("new course added!");
                    setTimeout(this.refresh, 1000);
                })
                .catch((error) => {
                    toast.error("Something Went Wrong!");
                });
        }
    }

    submitCourseUpdate(e) {
        let url = BASE_URL;
        const query =
            'UPDATE courses SET moduleNo="' +
            this.state.currentCourse_moduleNo +
            '",moduleName="' +
            this.state.currentCourse_moduleName +
            '"  WHERE id=' +
            this.state.currentCourse_id +
            "";
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("course updated successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });

        this.setState({ name: "", trainerName: "", type: "", message: "" });
    }

    submitCourseDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE courses SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("courses deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {});

        this.setState({
            name: "",
            trainerName: "",
            startDate: "",
            endDate: "",
            type: "",
            refreshDate: "",
            message: "",
            topic: "",
            location: "",
        });
    }

    fetchCoursessData() {
        let url = BASE_URL;
        const query = `SELECT cr.* FROM courses cr where cr.status=1 `;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("courses", res.data);
                this.setState({ courses: res.data });
            })
            .catch((err) => {
                console.log("coueses data error: ", err);
            });
    }

    componentDidMount() {
        this.fetchCoursessData();
    }

    componentDidUpdate() {
        $(document).ready(function () {
            $("#datatable-keytable")
                .DataTable({
                    bSort: true,
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    dom: "lBfrtip",
                    buttons: ["copy", "csv", "excel", "print"],
                    lengthMenu: [
                        50, 100, 500, 1000, 2000, 5000, 10000, 50000, 100000,
                    ],
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderCoursesData() {
        const courses = this.state.courses;

        if (courses == null) {
            return null;
        }

        return courses.map((course) => {
            // define user type

            return (
                <tr>
                    <td>{course.id}</td>

                    <td>{course.moduleNo}</td>
                    <td>{course.moduleName}</td>

                    <td>
                        <a
                            href={`${course.contentFilePath}`}
                            target="_blank"
                            class="btn btn-primary btn-sm mx-1"
                            // download
                        >
                            <i class="fas fa-eye"></i>
                        </a>
                    </td>

                    <td className="" style={{ width: "180px" }}>
                        <button
                            class="btn btn-primary btn-sm mr-1"
                            onClick={() => {
                                this.setState({
                                    currentCourse_id: course.id,
                                    currentCourse_courseName: course.name,
                                    currentCourse_trainerName: course.tmname,
                                    currentCourse_auditNumber:
                                        course.auditNumber,
                                    currentCourse_type: course.type,
                                    currentCourse_issueDate: moment(
                                        course.issueDate
                                    ).format("DD / MM / YYYY"),
                                    currentCourse_refreshDate: moment(
                                        course.refresherCourseDate
                                    ).format("DD / MM / YYYY"),
                                    currentCourse_location: course.location,
                                });
                            }}
                            disabled
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-primary btn-sm mr-1"
                            onClick={(e) => {
                                this.setState({
                                    currentCourse_id: course.id,
                                    currentCourse_courseName: course.name,
                                    currentCourse_trainerName: course.tmname,
                                    currentCourse_auditNumber:
                                        course.auditNumber,
                                    currentCourse_type: course.type,
                                    currentCourse_issueDate: course.issueDate,
                                    currentCourse_refreshDate:
                                        course.refresherCourseDate,
                                    currentCourse_location: course.location,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete trainer?"
                                    )
                                ) {
                                    this.submitCourseDelete(course.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View courses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Topic Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentCourse_courseName}
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_courseName:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Trainer Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentCourse_trainerName}
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_trainerName:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Audit Number:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentCourse_auditNumber}
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_auditNumber:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Type:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={
                                    this.state.currentCourse_type == 1
                                        ? "Metro"
                                        : "Construction"
                                }
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_type: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Course Date:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentCourse_issueDate}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_issueDate: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                refresh Date:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentCourse_refreshDate}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_refreshDate:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Location:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentCourse_location}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_location: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Module No:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.currentCourse_moduleNo}
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_moduleNo: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Module Name:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.currentCourse_moduleName}
                                onChange={(e) => {
                                    this.setState({
                                        currentCourse_moduleName:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            this.submitCourseUpdate(e);
                            this.setState({ updateModal: false });
                        }}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2 mr-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-user-plus"></i>
                                                </span>
                                                &nbsp;Add New Course
                                            </button>

                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New Course
                                                                {/*  */}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Module
                                                                        No:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="moduleNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeCourseModuleNo(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .auditNumberError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Module
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="moduleName"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeCourseModuleName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .auditNumberError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Upload
                                                                        Content
                                                                        File:
                                                                    </label>
                                                                    <TextField
                                                                        name="fileToUpload"
                                                                        size="small"
                                                                        type="file"
                                                                        variant="outlined"
                                                                        className="mr-3 ml-3 mt-1 inputField"
                                                                        onChange={
                                                                            this
                                                                                .handleContentFileInputChange
                                                                        }
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.submitFile(
                                                                        "content"
                                                                    );
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for update  starts here*/}

                                        {/* modal for update ends here */}
                                        <table
                                            id="datatable-keytable"
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>

                                                    <th>
                                                        Module No{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Module Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>

                                                    <th>
                                                        Content{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderCoursesData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
