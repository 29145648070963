import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "../common/global.js";
//react toast
import { toast } from "react-toastify";
import moment from "moment";
import { format } from "date-fns";

import "./style.css";
import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { AuthContext } from "../../Context/authContext";
import iclmLogo from "./assets/iclmLogo.jpeg";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

// const PaymentMode = [
//   { label: "Cash", name: "cash", value: "1" },
//   { label: "cheque", name: "cheque", value: "2" },
//   { label: "Online", name: "Online", value: "3" },
// ];

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class QuotationManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quotationId: 1,
            description: null,
            moduleNo: null,
            rate: null,
            perPersonCharge: null,
            date: format(new Date(), "dd/MM/yyyy"),
            toName: null,
            toAddress: null,
            ka: null,
            emailId: null,
            sub: null,
            addedItems: [],
            id: 1,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    getLatestId = () => {
        let url = BASE_URL;
        const query = `SELECT id FROM quotationMaster ORDER BY id DESC LIMIT 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("latest id data: ", res.data);
                this.setState({
                    quotationId:
                        (res.data[0] != null ? res.data[0]["id"] : 0) + 1,
                });
            })
            .catch((err) => {
                console.log("latest id data fetch error: ", err);
            });
    };

    addItems = () => {
        // if (!this.state.toName) return;
        console.log("hi");
        // let items = this.state.itemList;
        let items = this.state.addedItems;
        const ifExists = items.find(
            (item) => item.toName === this.state.toName
        );
        if (ifExists) {
            items = items.map((item) => {
                if (item.toName === this.state.toName) {
                    return {
                        id: this.state.id,
                        quotationId: this.state.quotationId,
                        description: this.state.description,
                        moduleNo: this.state.moduleNo,
                        rate: this.state.rate,
                        perPersonCharge: this.state.perPersonCharge,
                    };
                }
            });
        } else {
            items.push({
                id: this.state.id,
                quotationId: this.state.quotationId,
                description: this.state.description,
                moduleNo: this.state.moduleNo,
                rate: this.state.rate,
                perPersonCharge: this.state.perPersonCharge,
            });
        }
        // items.push({
        //     particular: this.state.particular,
        //     mark: this.state.mark,
        //     totalBoxes: this.state.totalBoxes,
        //     weightPerBox: this.state.weightPerBox,
        //     weight: this.state.weight,
        //     rate: this.state.rate,
        //     amount: this.state.amount,
        // });

        this.setState({ addedItems: items });
        console.log(this.state.addedItems);

        // update total & balance
        // let total = Number(this.state.total) + Number(this.state.amount);
        // let total =
        //   Number(this.state.rate * this.state.qty);
        // this.setState({ total: total }, this.calculateTaxes);
        // let balance = total - Number(this.state.paid);
        // this.setState({ balance: balance });
        // this.calculateTaxes();
    };

    saveBill = () => {
        const newDate = moment(new Date()).format("YYYY-MM-DD");
        let query;

        query = `INSERT INTO quotationMaster (id, date, toName, toAddress, ka, emailId, sub, status) values(
        ${this.state.quotationId}, 
        ${newDate},
        '${this.state.toName}', 
        '${this.state.toAddress}',
        '${this.state.ka}',
        '${this.state.emailId}',
        '${this.state.sub}',
        1)`;
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(BASE_URL, data)
            .then((res) => {
                toast.success("Generated Quotation successfully");
                this.setState(
                    { billId: res.data.insertId },
                    this.insertBillList()
                );
            })
            .catch((err) => {
                toast.error("Failed to Generate Bill ");
            });
    };

    insertBillList = () => {
        let url = BASE_URL;

        // 1.  insert into deliveryMemoList
        this.state.addedItems.map((item, index) => {
            const query = `INSERT INTO quotationItems(quotationId, description, moduleNo, rate, perPersonCharge,  status) VALUES(
      ${this.state.quotationId},
      '${this.state.description}',
      '${this.state.moduleNo}',
      '${this.state.rate}',
      '${this.state.perPersonCharge}',
      1
    )`;
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "insert QuotationItems successfull, index: ",
                        index
                    );
                })
                .catch((err) => {
                    console.log(
                        "failed to insert QuotationItems, error: ",
                        err
                    );
                });
        });
    };
    // onSubmit(e) {
    //   let url = BASE_URL;
    //   const query =
    //     'INSERT INTO courses (name,candidateName, startDate, endDate, type, refresherCourseDate, message) values("' +
    //     this.state.newCourse.name +
    //     '", "' +
    //     this.state.newCourse.candidateName +
    //     '" ,"' +
    //     this.state.newCourse.startDate +
    //     '", "' +
    //     this.state.newCourse.endDate +
    //     '","' +
    //     this.state.newCourse.type +
    //     '","' +
    //     this.state.newCourse.refreshDate +
    //     '","' +
    //     this.state.newCourse.message +
    //     '") ';
    //   console.log(query)
    //   let data = { crossDomain: true, crossOrigin: true, query: query };
    //   axios
    //     .post(url, data)
    //     .then((res) => {
    //       console.log(res.data);
    //       toast("new course added!");
    //       setTimeout(this.refresh, 1000);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    //   this.setState({ name: "", candidateName: "", startDate: "", endDate: "", type: "", refreshDate: "", message: "" });
    // }

    // submitCourseUpdate(e) {
    //   let url = BASE_URL;
    //   const query =
    //     'UPDATE courses SET name="' +
    //     this.state.currentCourse_courseName +
    //     '",candidateName="' +
    //     this.state.currentCourse_candidateName +
    //     '",type="' +
    //     this.state.currentCourse_type +
    //     '", message="' +
    //     this.state.currentCourse_message +
    //     '"  WHERE id=' +
    //     this.state.currentCourse_id +
    //     "";

    //   console.log(query);

    //   let data = { crossDomain: true, crossOrigin: true, query: query };
    //   axios
    //     .post(url, data)
    //     .then((res) => {
    //       console.log(res.data);
    //       toast("course updated successfully!");
    //       setTimeout(this.refresh, 1000);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    //   this.setState({ name: "", candidateName: "", type: "", message: "" });
    // }

    // submitCourseDelete(id) {
    //   let url = BASE_URL;
    //   const query = `UPDATE courses SET status = -1 where id = ${id};`;

    //   let data = { crossDomain: true, crossOrigin: true, query: query };
    //   axios
    //     .post(url, data)
    //     .then((res) => {
    //       console.log(res.data);
    //       toast("courses deleted successfully!");
    //       setTimeout(this.refresh, 1000);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    //   this.setState({ name: "", candidateName: "", startDate: "", endDate: "", type: "", refreshDate: "", message: "" });
    // }

    // fetchCoursessData() {
    //   let url = BASE_URL;
    //   const query = `SELECT * FROM courses where status = 1;`;
    //   let data = { crossDomain: true, crossOrigin: true, query: query };

    //   axios
    //     .post(url, data)
    //     .then((res) => {
    //       console.log("coueses data: ", res.data);
    //       this.setState({ courses: res.data });
    //     })
    //     .catch((err) => {
    //       console.log("coueses data error: ", err);
    //     });
    // }

    componentDidMount() {
        // this.fetchCoursessData();
        this.getLatestId();
    }

    render() {
        return (
            <form
                className="mb-5"
                style={{ margin: "10px" }}
                onSubmit={(e) => e.preventDefault()}
            >
                <div>
                    {/* Content Wrapper. Contains page content */}
                    <div className="">
                        {/* Main content */}
                        <div className="row">
                            <TextField
                                id="Office At"
                                label="To Name"
                                variant="outlined"
                                className={"mr-2"}
                                value={this.state.toName}
                                onChange={(e) =>
                                    this.setState({ toName: e.target.value })
                                }
                                size="small"
                            />

                            <TextField
                                id="Office At"
                                label="To Address"
                                variant="outlined"
                                className={"mr-2"}
                                value={this.state.toAddress}
                                onChange={(e) =>
                                    this.setState({ toAddress: e.target.value })
                                }
                                size="small"
                            />

                            <TextField
                                id="trainingAddress"
                                label="K/A"
                                variant="outlined"
                                className="mr-2"
                                value={this.state.ka}
                                onChange={(e) =>
                                    this.setState({ ka: e.target.value })
                                }
                                size="small"
                            />

                            <TextField
                                id="emailId"
                                label="emailId"
                                variant="outlined"
                                className={this.state.emailId}
                                value={this.state.gstin}
                                onChange={(e) =>
                                    this.setState({ emailId: e.target.value })
                                }
                                size="small"
                            />

                            <TextField
                                id="subject"
                                label="subject"
                                variant="outlined"
                                className="mr-2 ml-2"
                                value={this.state.sub}
                                onChange={(e) =>
                                    this.setState({ sub: e.target.value })
                                }
                                size="small"
                            />
                        </div>

                        <div
                            className="row"
                            style={{ marginTop: ".3em", marginBottom: "2em" }}
                        >
                            <TextField
                                id="description"
                                label="description"
                                variant="outlined"
                                className="mr-2"
                                value={this.state.description}
                                onChange={(e) =>
                                    this.setState({
                                        description: e.target.value,
                                    })
                                }
                                size="small"
                            />

                            <TextField
                                id="moduleNo"
                                label="Module No"
                                variant="outlined"
                                className="mr-2 "
                                value={this.state.moduleNo}
                                onChange={(e) =>
                                    this.setState({ moduleNo: e.target.value })
                                }
                                size="small"
                            />
                            <TextField
                                id="rate"
                                label="UOM"
                                variant="outlined"
                                className="mr-2 "
                                value={this.state.rate}
                                onChange={(e) =>
                                    this.setState({ rate: e.target.value })
                                }
                                size="small"
                            />
                            <TextField
                                id="perPersonCharge"
                                label="Amount"
                                variant="outlined"
                                className="mr-2 "
                                value={this.state.perPersonCharge}
                                onChange={(e) =>
                                    this.setState({
                                        perPersonCharge: e.target.value,
                                    })
                                }
                                size="small"
                            />

                            <Button
                                variant="contained"
                                className="btn btn-primary"
                                onClick={this.addItems}
                                disabled={
                                    !this.state.toName ||
                                    !this.state.toAddress ||
                                    !this.state.ka ||
                                    !this.state.emailId ||
                                    !this.state.sub ||
                                    !this.state.description ||
                                    !this.state.moduleNo ||
                                    !this.state.rate ||
                                    !this.state.perPersonCharge
                                }
                            >
                                Add
                            </Button>

                            <hr />
                        </div>
                        <div
                            style={{ border: "1px solid black" }}
                            ref={(el) => (this.printComponentRef = el)}
                        >
                            <div className="row">
                                <div className="col-9">
                                    <h3
                                        className="text-center"
                                        style={{ margin: "1em" }}
                                    >
                                        Institute of Construction Equipment’s &
                                        Lifting Machines
                                    </h3>
                                    <h6
                                        className="text-center"
                                        style={{ margin: "1em" }}
                                    >
                                        (Approved by Director Industrial Safety
                                        and Health, Government of Gujarat)
                                    </h6>
                                </div>
                                <div className="col-3">
                                    <img
                                        height={150}
                                        src={iclmLogo}
                                        style={{
                                            float: "right",
                                            marginBottom: "-1em",
                                            marginRight: "1.5em",
                                        }}
                                    ></img>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-8">
                                    <p style={{ margin: "1em" }}>
                                        Date:{" "}
                                        <b>
                                            {moment(new Date()).format(
                                                "D / M / YYYY"
                                            )}
                                        </b>
                                    </p>
                                    <p style={{ margin: "1em" }}>
                                        Q. No: <b>{this.state.quotationId}</b>
                                    </p>
                                    <p style={{ margin: "1em" }}>
                                        To,
                                        <br />
                                        M/S.<b>{this.state.toName}</b> <br />
                                        Add: <b>{this.state.toAddress}</b>
                                        <br />
                                        K/A: <b>{this.state.ka}</b>
                                        <br />
                                        Email Id: <b>{this.state.emailId}</b>
                                        <br />
                                        <br />
                                        Sub: <b>{this.state.sub}</b>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p style={{ margin: "1em" }}>
                                    Dear Sir, Greetings from Team ICLM. Thank
                                    you for your interest in our services. ICLM
                                    is a dual recognised institute by the
                                    Directorate of Industrial Safety and Health
                                    (DISH)-Gujarat and the Maharashtra State
                                    Board of Skill Development and Examination
                                    (MSBSDE) for safety training and Diploma
                                    programs. Our Motto is “विद्या लाभस्य
                                    कारणम्” which means Education for Benefits.
                                    As per our discussion on said subject please
                                    find below mentioned price for the training
                                    programs.
                                </p>
                                <br />
                                <p style={{ margin: "1em" }}>
                                    SCOPE OF TRAINING:
                                    <br />
                                    1. Training could be arranged for all level
                                    of management.
                                    <br />
                                    2. Different kinds of training can be
                                    conducted I.e Awareness Training; Basic
                                    Learning Training; Supervisor Level Training
                                    and Capability Building Training.
                                    <br />
                                    3. Duration of Training-
                                    <br />
                                    A) Awareness Training - Half Day
                                    <br />
                                    B) Basic Learning Training - 1 Day
                                    <br />
                                    C) Supervisor Level Training - 2 Day
                                    <br />
                                    D) Capability Building Training - 5 Day
                                    <br />
                                    4. Attendance sheet will be filled up along
                                    with Name, Designation, Contact Number &
                                    Sign etc. of the attendees.
                                    <br />
                                    5. Training shall not be carried out in
                                    heavy wind pressure, heavy rain,
                                    thunderstorm & during night.
                                    <br />
                                    6. Any Conditional Certificate shall not be
                                    issued.
                                    <br />
                                    7. Certificates will be provided as per
                                    Factories Act 1948 sec 111-A (ii).
                                    <br />
                                </p>
                                <h5 style={{ margin: "1em" }}>
                                    DESCRIPTION AND PRICE:
                                </h5>
                                <table id="quotation">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Description</th>
                                            <th>Module No</th>
                                            <th>UOM</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>

                                    {this.state.addedItems.length > 0 ? (
                                        <tbody>
                                            {this.state.addedItems.map(
                                                (item, index) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>
                                                                {
                                                                    item.quotationId
                                                                }{" "}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.description
                                                                }{" "}
                                                            </td>
                                                            <td>
                                                                {item.moduleNo}
                                                            </td>
                                                            <td>{item.rate}</td>
                                                            <td>
                                                                {
                                                                    item.perPersonCharge
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                            <br></br>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="8">
                                                    No items added
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                                <h5 style={{ margin: "1em" }}>
                                    OTHER TERMS & CONDITIONS: -
                                </h5>

                                <p style={{ margin: "1em" }}>
                                    1. Competency certificate shall be awarded
                                    to only those participants who will attend
                                    the course for the entire duration.
                                    <br />
                                    2. Accessories (Projector, White Board &
                                    Marker) has to be provided by client in case
                                    of, “On Site” training.
                                    <br />
                                    3. Travelling, Lodging, fooding shall be
                                    charged extra for on-site training.
                                    <br />
                                    4. If due to any reason training scheduled
                                    is not conducted/postponed then Rs. 4000/-
                                    will be charged (on-site per visit).
                                    <br />
                                    5. Above mentioned standard rates are non-
                                    negotiable.
                                    <br />
                                </p>
                                <h5 style={{ margin: "1em" }}>
                                    PAYMENT TERMS-
                                </h5>
                                <p style={{ margin: "1em" }}>
                                    1. Above mentioned rates are excluding GST.
                                    GST is applicable (i.e 18%)
                                    <br />
                                    2. Certificates will be provided only after
                                    payment against proforma invoice only. (0%
                                    credibility)
                                    <br />
                                    3. Customer will not be able to claim GST
                                    before full settlement of the payment.
                                    <br />
                                </p>
                                <h5 style={{ margin: "1em" }}>
                                    BANKING DETAILS-
                                </h5>
                                <p style={{ margin: "1em" }}>
                                    A/C Name: Institute of Construction
                                    Equipments and Lifting Machines Axis Bank,
                                    <br /> A/C no: '917020066522350 ,<br /> IFSC
                                    Code: UTIB0000772
                                </p>
                                <h5 style={{ margin: "1em" }}>
                                    PRICE VALIDATION-
                                </h5>
                                <p style={{ margin: "1em" }}>
                                    • Price of the quotation is valid for a
                                    period of 30days only from the date of issue
                                    of quotation.
                                </p>
                                <h5 style={{ margin: "1em" }}>CERTIFICATION</h5>
                                <p style={{ margin: "1em" }}>
                                    • Certificates are provided on the basis of
                                    training ; not on skill.
                                </p>
                                <h5 style={{ margin: "1em" }}>VARIATIONS</h5>
                                <p style={{ margin: "1em" }}>
                                    • Any kind of changes/variations made while
                                    carrying out training then it would be
                                    charged extra.
                                    <br />
                                    We are looking forward for your favorable
                                    response and to get another opportunity to
                                    serve & make you happy through our services.
                                </p>

                                <p style={{ margin: "1em" }}>
                                    Regards
                                    <br />
                                    For
                                    <br />
                                    ICLM
                                    <br />
                                    _________
                                    <br />
                                    _________
                                    <br />
                                    _________
                                </p>
                                <div>
                                    <p
                                        className="text-center"
                                        style={{
                                            fontSize: "12px",
                                            color: "grey",
                                        }}
                                    >
                                        Office:- 607/R Square, Veena Nagar,
                                        Opposite kamgar Hospital, LBS Road,
                                        <br /> Mulund West- 400 080
                                        <br /> Email:iclmconnect@gmail.com,
                                        www.iclm.org.in, Mobile: 09321905149
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* /.content */}
                    </div>
                    {/* /.content-wrapper */}
                </div>
                <div>
                    <ReactToPrint content={() => this.printComponentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button
                                    onClick={handlePrint}
                                    className="btn btn-primary mt-2 mr-1"
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right" }}
                                    // disabled={
                                    //     (this.state.partyName ||
                                    //         this.state.newPartyName) &&
                                    //         this.state.address
                                    //         ? false
                                    //         : true
                                    // }
                                >
                                    Print
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Button
                        className="btn btn-primary mt-2 mr-1"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.saveBill}
                        // disabled={
                        //   this.state.partyName
                        //     ? false
                        //     : true
                        // }
                    >
                        Save bill
                    </Button>
                    <Button
                        className="btn btn-primary mt-2 mr-1 "
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleClear}
                    >
                        clear
                    </Button>
                </div>
            </form>
        );
    }
}
