import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: null,
            newCustomer: {
                customerId: null,
                org: null,
                projectName: null,
                siteAddress: null,
                client: null,
                contactPerson: null,
                category: null,
                mobileNo: null,
                emailId: null,
                status: null,
            },
            orgError: null,
            projectNameError: null,
            siteAddressError: null,
            clientError: null,
            contactPersonError: null,
            categoryError: null,
            mobileNoError: null,
            emailIdError: null,

            currentcustomer_id: null,
            currentcustomer_org: null,
            currentcustomer_projectName: null,
            currentcustomer_siteAddress: null,
            currentcustomer_client: null,
            currentcustomer_contactPerson: null,
            currentcustomer_category: null,
            currentcustomer_mobileNo: null,
            currentcustomer_emailId: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeOrg(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.org = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    onChangeProjectName(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.projectName = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    onChangeSiteAddress(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.siteAddress = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    onChangeClient(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.client = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    onChangeContactPerson(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.contactPerson = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    onChangeCategory(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.category = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    onChangeMobileNo(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.mobileNo = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    onChangeEmailId(e) {
        let newCustomer = this.state.newCustomer;
        newCustomer.emailId = e.target.value;
        this.setState({ newCustomer: newCustomer });
    }

    validate() {
        let status = true;
        if (this.state.newCustomer.org == null) {
            this.setState({ orgError: "please fill up the field" });
            console.log(this.state.orgError);
            status = false;
        }
        if (this.state.newCustomer.projectName == null) {
            this.setState({ projectNameError: "please fill up the field" });
            console.log(this.state.projectNameError);
            status = false;
        }
        if (this.state.newCustomer.siteAddress == null) {
            this.setState({ siteAddressError: "please fill up the field" });
            console.log(this.state.siteAddressError);
            status = false;
        }
        if (this.state.newCustomer.client == null) {
            this.setState({ clientError: "please fill up the field" });
            console.log(this.state.clientError);
            status = false;
        }
        if (this.state.newCustomer.contactPerson == null) {
            this.setState({ contactPersonError: "please fill up the field" });
            console.log(this.state.contactPersonError);
            status = false;
        }
        if (this.state.newCustomer.category == null) {
            this.setState({ categoryError: "please fill up the field" });
            console.log(this.state.categoryError);
            status = false;
        }
        if (this.state.newCustomer.mobileNo == null) {
            this.setState({ mobileNoError: "please fill up the field" });
            console.log(this.state.mobileNoError);
            status = false;
        }
        if (this.state.newCustomer.emailId == null) {
            this.setState({ emailIdError: "please fill up the field" });
            console.log(this.state.emailIdError);
            status = false;
        }
        return status;
    }
    onSubmit(e) {
        if (this.validate()) {
            let url = BASE_URL;
            const query = `INSERT INTO customerMaster (org, projectName, siteAddress, client, contactPerson, category,mobileNo, emailId, status) values(
                '${this.state.newCustomer.org}',
                '${this.state.newCustomer.projectName}',
                '${this.state.newCustomer.siteAddress}',
                '${this.state.newCustomer.client}',
                '${this.state.newCustomer.contactPerson}',
                '${this.state.newCustomer.category}',
                '${this.state.newCustomer.mobileNo}',
                '${this.state.newCustomer.emailId}',
                1
            ) `;
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    toast("new customer added!");
                    setTimeout(this.refresh, 1000);
                })
                .catch((error) => {
                    toast("Something went wrong");
                });

            this.setState({
                org: "",
                projectName: "",
                siteAddress: "",
                client: "",
                contactPerson: "",
                category: "",
                mobileNo: "",
                emailId: "",
            });
        }
    }

    submitCustomerUpdate(e) {
        let url = BASE_URL;
        const query = `UPDATE customerMaster SET org = '${this.state.currentcustomer_org}',
            projectName = '${this.state.currentcustomer_projectName}',
            siteAddress = '${this.state.currentcustomer_siteAddress}',
            client = '${this.state.currentcustomer_client}',
            contactPerson = '${this.state.currentcustomer_contactPerson}',
            category = '${this.state.currentcustomer_category}',
            mobileNo = '${this.state.currentcustomer_mobileNo}',
            emailId = '${this.state.currentcustomer_emailId}'  WHERE id = '${this.state.currentcustomer_id}'
             `;

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("customer updated successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast("Something went wrong");
            });

        this.setState({
            org: "",
            projectName: "",
            siteAddress: "",
            client: "",
            contactPerson: "",
            category: "",
            mobileNo: "",
            emailId: "",
        });
    }

    submitTrainerDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE customerMaster SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("customer deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast("Something went wrong");
            });

        this.setState({
            org: "",
            projectName: "",
            siteAddress: "",
            client: "",
            contactPerson: "",
            category: "",
            mobileNo: "",
            emailId: "",
        });
    }

    fetchCustomersData() {
        let url = BASE_URL;
        const query = `SELECT * FROM customerMaster where status = 1 ;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ customers: res.data });
            })
            .catch((err) => {});
    }

    componentDidMount() {
        this.fetchCustomersData();
    }
    componentDidUpdate() {
        $(document).ready(function () {
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderTrainersData() {
        const customers = this.state.customers;

        if (customers == null) {
            return null;
        }

        return customers.map((customer) => {
            return (
                <tr>
                    <td>{customer.id}</td>
                    <td>{customer.org}</td>
                    <td>{customer.projectName}</td>
                    <td>{customer.siteAddress}</td>
                    <td>{customer.client}</td>
                    <td>{customer.contactPerson}</td>
                    <td>{customer.category}</td>
                    <td>{customer.mobileNo}</td>
                    <td>{customer.emailId}</td>
                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState({
                                    currentcustomer_id: customer.id,
                                    currentcustomer_org: customer.org,
                                    currentcustomer_projectName:
                                        customer.projectName,
                                    currentcustomer_siteAddress:
                                        customer.siteAddress,
                                    currentcustomer_client: customer.client,
                                    currentcustomer_emailId: customer.emailId,
                                    currentcustomer_category: customer.category,
                                    currentcustomer_mobileNo: customer.mobileNo,
                                    currentcustomer_contactPerson:
                                        customer.contactPerson,
                                    viewModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={(e) => {
                                this.setState({
                                    currentcustomer_id: customer.id,
                                    currentcustomer_org: customer.org,
                                    currentcustomer_projectName:
                                        customer.projectName,
                                    currentcustomer_siteAddress:
                                        customer.siteAddress,
                                    currentcustomer_client: customer.client,
                                    currentcustomer_emailId: customer.emailId,
                                    currentcustomer_category: customer.category,
                                    currentcustomer_contactPerson:
                                        customer.contactPerson,
                                    currentcustomer_mobileNo: customer.mobileNo,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete customer?"
                                    )
                                ) {
                                    this.submitTrainerDelete(customer.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Org:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_org}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_org: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Project Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_projectName}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_projectName:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Site Address:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_siteAddress}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_siteAddress:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Client:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_client}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_client: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                Contact Person:
                            </label>
                            <textarea
                                className="form-control"
                                name="address"
                                value={this.state.currentcustomer_contactPerson}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_contactPerson:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                                id="siteAddress"
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Category:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentcustomer_category}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_category:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Mobile No:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentcustomer_mobileNo}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_mobileNo:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Email Id:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentcustomer_emailId}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_emailId: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Org:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_org}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_org: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Project Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_projectName}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_projectName:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Site Address:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_siteAddress}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_siteAddress:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Client:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentcustomer_client}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_client: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                Contact Person:
                            </label>
                            <textarea
                                className="form-control"
                                name="address"
                                value={this.state.currentcustomer_contactPerson}
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_contactPerson:
                                            e.target.value,
                                    });
                                }}
                                id="siteAddress"
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Category:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentcustomer_category}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_category:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Mobile No:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentcustomer_mobileNo}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_mobileNo:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Email Id:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentcustomer_emailId}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentcustomer_emailId: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            this.submitCustomerUpdate(e);
                            this.setState({ updateModal: false });
                        }}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-user-plus"></i>
                                                </span>
                                                &nbsp;Add New Customer
                                            </button>
                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New Customer
                                                                {/*  */}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Org:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeOrg(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .orgError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Project
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeProjectName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .projectNameError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Site
                                                                        Address:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeSiteAddress(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .siteAddressError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Client:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeClient(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .clientError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Contact
                                                                        Person:
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        pattern=".+@globex\.com"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeContactPerson(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .contactPersonError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Category:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeCategory(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .categoryError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Mobile
                                                                        No:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeMobileNo(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .mobileNoError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Email
                                                                        Id:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeEmailId(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .emailIdError
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.onSubmit(
                                                                        e
                                                                    );
                                                                }}
                                                                // disabled={
                                                                //     !this.state.newCustomer.org ||
                                                                //     !this.state.newCustomer.projectName ||
                                                                //     !this.state.newCustomer.siteAddress ||
                                                                //     !this.state.newCustomer.client ||
                                                                //     !this.state.newCustomer.contactPerson ||
                                                                //     !this.state.newCustomer.category ||
                                                                //     !this.state.newCustomer.mobileNo ||
                                                                //     !this.state.newCustomer.emailId
                                                                // }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for update  starts here*/}

                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Org{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Project Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Site Address{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Client{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Contact Person{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Category{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Mobile No.{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Email Id.{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderTrainersData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
