import React, { Component } from "react";
import PropTypes from "prop-types";
import "./dashboard.css";
import { AuthContext } from "../../Context/authContext";
import { BASE_URL } from "./../common/global.js";

// Axios for HTTP req
const axios = require("axios");

export default class Dashboard extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {
            trainersCount: 0,
           candidateCount: 0,
           quotationCount: 0,
           invoiceCount: 0,
        };
    }




    fetchTrainersCount() {
        let url = BASE_URL;

        const query = `SELECT COUNT(id) as trainersCount FROM trainerMaster WHERE status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ trainersCount: res.data[0]["trainersCount"] });
            })
            .catch((err) => {
                console.log("trainers data error: ", err);
            });
    }

    fetchCandidateCount() {
        let url = BASE_URL;

        const query = `SELECT COUNT(id) as candidateCount FROM candidateMaster WHERE status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ candidateCount: res.data[0]["candidateCount"] });
            })
            .catch((err) => {
                console.log("candidate data error: ", err);
            });
    }

    fetchUQuotationCount() {
        let url = BASE_URL;

        const query = `SELECT COUNT(id) as quotationCount FROM quotationMaster WHERE status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ quotationCount: res.data[0]["quotationCount"] });
            })
            .catch((err) => {
                console.log("quotation data error: ", err);
            });
    }

    fetchInvoiceCount() {
        let url = BASE_URL;

        const query = `SELECT COUNT(id) as invoiceCount FROM invoiceMaster WHERE status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ invoiceCount: res.data[0]["invoiceCount"] });
            })
            .catch((err) => {
                console.log("invoice data error: ", err);
            });
    }



    componentDidMount() {
        this.fetchTrainersCount();
        this.fetchCandidateCount();
        this.fetchUQuotationCount();
        this.fetchInvoiceCount();

    }
    render() {
        return (
            <>
                <div>
                    {/* Main content */}
                    <div class="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0">Dashboard</h1>
                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        <section className="content">
                            <div className="container-fluid">
                                {/* Small boxes (Stat box) */}
                                <div className="row">
                                    <div className="col-lg-3 col-6">
                                        {/* small box */}
                                        <div className="small-box bg-success text-center" style={{height:'108px'}}>
                                            <div className="inner">
                                                <h3>
                                                Trainings 
                                                </h3>
                                                <h1>{this.state.trainersCount}{" "}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ./col */}
                                    <div className="col-lg-3 col-6">
                                        {/* small box */}
                                        <div className="small-box bg-warning text-center" style={{height:'108px'}}>
                                            <div className="inner">
                                                <h3>
                                                Candidates 

                                                </h3>
                                                <h1>{this.state.candidateCount}{" "}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ./col */}
                                    <div className="col-lg-3 col-6">
                                        {/* small box */}
                                        <div className="small-box bg-primary text-center" style={{height:'108px'}}>
                                            <div className="inner">
                                                <h3>
                                                Quotation 
                                                </h3>
                                                <h1>{this.state.quotationCount}{" "}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ./col */}
                                    <div className="col-lg-3 col-6">
                                        {/* small box */}
                                        <div className="small-box bg-danger text-center" style={{height:'108px'}}>
                                            <div className="inner">
                                                <h3>
                                                Invoices  
                                                </h3>
                                                <h1>{this.state.invoiceCount}{" "}</h1>
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>

                                {/* todays boxes */}



                                {/* /.row */}
                            </div>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}
