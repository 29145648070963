import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

// const useStyles = makeStyles((theme) => ({
//     root: {
//       '& .MuiTextField-root': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//   }));

export default class Trainers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trainers: null,
            selectedFile: null,
            activeDocPath: null,
            newTrainer: {
                trainerId: null,
                firstName: null,
                lastName: null,
                address: null,
                email: null,
                qualification: null,
                mobileNo: null,
                trainerId: null,
                firstNameError: null,
                lastNameError: null,
                addressError: null,
                emailError: null,
                qualificationError: null,
                mobileNoError: null,
                status: null,
            },

            currentTrainer_id: null,
            currentTrainer_firstName: null,
            currentTrainer_lastName: null,
            currentTrainer_address: null,
            currentTrainer_qualification: null,
            currentTrainer_email: null,
            currentTrainer_mobileNo: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeTrainerFirstName(e) {
        let newTrainer = this.state.newTrainer;
        newTrainer.firstName = e.target.value;
        this.setState({ newTrainer: newTrainer });
    }

    onChangeTrainerLastName(e) {
        let newTrainer = this.state.newTrainer;
        newTrainer.lastName = e.target.value;
        this.setState({ newTrainer: newTrainer });
    }

    onChangeTrainerAddress(e) {
        let newTrainer = this.state.newTrainer;
        newTrainer.address = e.target.value;
        this.setState({ newTrainer: newTrainer });
    }

    onChangeTrainerQualification(e) {
        let newTrainer = this.state.newTrainer;
        newTrainer.qualification = e.target.value;
        this.setState({ newTrainer: newTrainer });
    }

    onChangeTrainerEmail(e) {
        let newTrainer = this.state.newTrainer;
        newTrainer.email = e.target.value;
        this.setState({ newTrainer: newTrainer });
    }

    onChangeTrainerMobileNo(e) {
        let newTrainer = this.state.newTrainer;
        newTrainer.mobileNo = e.target.value;
        this.setState({ newTrainer: newTrainer });
    }

    validate() {
        let status = true;
        if (this.state.newTrainer.firstName == null) {
            this.setState({ firstnameError: "please fill up the field" });
            console.log(this.state.newTrainer.firstNameError);
            status = false;
        }

        if (this.state.newTrainer.lastName == null) {
            this.setState({ lastNameError: "please fill up the field" });
            console.log(this.state.newTrainer.lastNameError);
            status = false;
        }
        if (this.state.newTrainer.address == null) {
            this.setState({ addressError: "please fill up the field" });
            console.log(this.state.newTrainer.addressError);
            status = false;
        }
        if (this.state.newTrainer.qualification == null) {
            this.setState({ qualificationError: "please fill up the field" });
            console.log(this.state.newTrainer.qualificationError);
            status = false;
        }
        if (this.state.newTrainer.email == null) {
            this.setState({ emailError: "please fill up the field" });
            console.log(this.state.newTrainer.emailError);
            status = false;
        }
        if (this.state.newTrainer.mobileNo == null) {
            this.setState({ mobileNoError: "please fill up the field" });
            console.log(this.state.newTrainer.mobileNoError);
            status = false;
        }

        return status;
    }

    handleInputChange = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
        });
    };

    submitFile() {
        const data = new FormData();
        data.append("fileToUpload", this.state.selectedFile);
        let url = "https://iclm.5techg.com/api/fileUpload/";

        axios.post(url, data).then((res) => {
            // then print response status

            this.setState({ activeDocPath: res.data }, this.onSubmit);
        });
    }

    onSubmit() {
        if (this.validate()) {
            const { activeDocPath } = this.state;
            const query = `INSERT INTO trainerMaster (firstName, lastName, address, qualification, emailId, mobileNo, filePath) values(
                '${this.state.newTrainer.firstName}',
                '${this.state.newTrainer.lastName}',
                '${this.state.newTrainer.address}',
                '${this.state.newTrainer.qualification}',
                '${this.state.newTrainer.email}',
                '${this.state.newTrainer.mobileNo}',
                "${activeDocPath}"
            ) `;
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(BASE_URL, data)
                .then((res) => {
                    toast("new trainer added!");
                    setTimeout(this.refresh, 1000);
                })
                .catch((error) => {
                    toast("Something went wrong");
                });

            this.setState({
                firstName: "",
                lastName: "",
                address: "",
                qualification: "",
                email: "",
                mobileNo: "",
            });
        }
    }

    submitTrainerUpdate(e) {
        let url = BASE_URL;
        const query =
            'UPDATE trainerMaster SET firstName="' +
            this.state.currentTrainer_firstName +
            '",lastname="' +
            this.state.currentTrainer_lastName +
            '", emailId="' +
            this.state.currentTrainer_email +
            '",address="' +
            this.state.currentTrainer_address +
            '",qualification="' +
            this.state.currentTrainer_qualification +
            '", mobileNo="' +
            this.state.currentTrainer_mobileNo +
            '"  WHERE id=' +
            this.state.currentTrainer_id +
            "";

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("trainer updated successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast("Something went wrong");
            });

        this.setState({
            firstName: "",
            lastName: "",
            qualification: "",
            email: "",
            mobileNo: "",
            address: "",
        });
    }

    submitTrainerDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE trainerMaster SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("trainer deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast("Something went wrong");
            });

        this.setState({
            firstName: "",
            lastName: "",
            address: "",
            qualification: "",
            email: "",
            mobileNo: "",
        });
    }

    fetchTrainersData() {
        let url = BASE_URL;
        const query = `SELECT * FROM trainerMaster where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ trainers: res.data });
            })
            .catch((err) => {});
    }

    componentDidMount() {
        this.fetchTrainersData();
    }
    componentDidUpdate() {
        $(document).ready(function () {
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderTrainersData() {
        const trainers = this.state.trainers;

        if (trainers == null) {
            return null;
        }

        return trainers.map((trainer) => {
            return (
                <tr>
                    <td>{trainer.id}</td>
                    <td>{trainer.firstName}</td>
                    <td>{trainer.lastName}</td>
                    <td>{trainer.address}</td>
                    <td>{trainer.qualification}</td>
                    <td>{trainer.emailId}</td>
                    <td>{trainer.mobileNo}</td>
                    <td>
                        <a
                            href={`${trainer.filePath}`}
                            target="_blank"
                            class="btn btn-primary btn-sm mx-1"
                            // download
                        >
                            <i class="fas fa-eye"></i>
                        </a>
                    </td>
                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState({
                                    currentTrainer_id: trainer.id,
                                    currentTrainer_firstName: trainer.firstName,
                                    currentTrainer_lastName: trainer.lastName,
                                    currentTrainer_address: trainer.address,
                                    currentTrainer_qualification:
                                        trainer.qualification,
                                    currentTrainer_email: trainer.emailId,
                                    currentTrainer_mobileNo: trainer.mobileNo,
                                    viewModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={(e) => {
                                this.setState({
                                    currentTrainer_id: trainer.id,
                                    currentTrainer_firstName: trainer.firstName,
                                    currentTrainer_lastName: trainer.lastName,
                                    currentTrainer_address: trainer.address,
                                    currentTrainer_qualification:
                                        trainer.qualification,
                                    currentTrainer_email: trainer.emailId,
                                    currentTrainer_mobileNo: trainer.mobileNo,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete trainer?"
                                    )
                                ) {
                                    this.submitTrainerDelete(trainer.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Trainer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                First Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_firstName}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_firstName:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Last Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_lastName}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_lastName: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Address:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_address}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_address: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Qualification:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_qualification}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_qualification:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                Email:
                            </label>
                            <textarea
                                className="form-control"
                                name="address"
                                value={this.state.currentTrainer_email}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_email: e.target.value,
                                    });
                                }}
                                id="siteAddress"
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Mobile No:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentTrainer_mobileNo}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_mobileNo: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Trainer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                First Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_firstName}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_firstName:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Last Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_lastName}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_lastName: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Address:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_address}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_address: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Qualification:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTrainer_qualification}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_qualification:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                Email:
                            </label>
                            <textarea
                                className="form-control"
                                name="address"
                                value={this.state.currentTrainer_email}
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_email: e.target.value,
                                    });
                                }}
                                id="siteAddress"
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Mobile No:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentTrainer_mobileNo}
                                className="form-control"
                                id="siteEngineers"
                                placeholder="1, 2, 3"
                                onChange={(e) => {
                                    this.setState({
                                        currentTrainer_mobileNo: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            this.submitTrainerUpdate(e);
                            this.setState({ updateModal: false });
                        }}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-user-plus"></i>
                                                </span>
                                                &nbsp;Add New Trainer
                                            </button>
                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New Trainer
                                                                {/*  */}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        First
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainerFirstName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .firstnameError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Last
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainerLastName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .lastNameError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Address:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainerAddress(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .addressError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Qualification:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainerQualification(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .qualificationError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Email:
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        pattern=".+@globex\.com"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainerEmail(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .emailError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Mobile
                                                                        No:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainerMobileNo(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .mobileNoError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Upload
                                                                        File:
                                                                    </label>
                                                                    <TextField
                                                                        name="fileToUpload"
                                                                        size="small"
                                                                        type="file"
                                                                        variant="outlined"
                                                                        className="mr-3 ml-3 mt-1 inputField"
                                                                        onChange={
                                                                            this
                                                                                .handleInputChange
                                                                        }
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.submitFile();
                                                                }}
                                                                // disabled={
                                                                //     !this.state.newTrainer.firstName ||
                                                                //     !this.state.newTrainer.lastName ||
                                                                //     !this.state.newTrainer.address ||
                                                                //     !this.state.newTrainer.qualification ||
                                                                //     !this.state.newTrainer.email ||
                                                                //     !this.state.newTrainer.mobileNo
                                                                // }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for update  starts here*/}

                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        First Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Last Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Address{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Qualification{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Email{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Mobile No{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Docs
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderTrainersData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
