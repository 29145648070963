import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";

import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class TrainingMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trainers: null,
            trainings: null,
            selectedFile: null,
            selectedContentFile: null,
            activeDocPath: null,

            newTraining: {
                name: null,
                type: null,
                refreshDate: null,
                issueDate: null,
                message: null,
                topic: null,
                loacation: null,
                trainerName: null,
                newTrainertId: 0,
                newCourceId: 0,
                newModuleNameId: 0,
                newTrainerName: null,
                newCoursesName: null,
                auditNumber: 0,
                newTrainerName: null,
                moduleName: null,
                moduleNo: null,
                status: null,
            },
            nameError: null,
            typeError: null,
            refreshDateError: null,
            issueDateError: null,
            messageError: null,
            topicError: null,
            loacationError: null,
            trainerNameError: null,
            moduleNoError: null,
            newTrainertId: 0,
            newTrainerNameError: null,
            auditNumberError: null,

            currentTraining_id: null,
            currentTraining_trainingName: null,
            currentTraining_trainerName: null,
            currentTraining_auditNumber: null,
            currentTraining_refreshDate: null,
            currentTraining_issueDate: null,
            currentTraining_message: null,
            currentTraining_topic: null,
            currentTraining_topicName: null,
            currentTraining_location: null,
            currentTraining_type: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeTrainingName(e) {
        let newTraining = this.state.newTraining;
        newTraining.name = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    onChangeTrainingTrainerId(e) {
        let newTraining = this.state.newTraining;
        newTraining.newTrainertId = e.target.value;
        this.setState({ newTraining: newTraining });
        console.log(newTraining.newTrainertId);
    }

    onChangeTrainingAuditNumber(e) {
        let newTraining = this.state.newTraining;
        newTraining.auditNumber = e.target.value;
        this.setState({ newTraining: newTraining });
    }
    onChangeTrainingmoduleNo(e) {
        let newTraining = this.state.newTraining;
        newTraining.moduleNo = e.target.value;
        this.setState({ newTraining: newTraining });
    }
    onChangeTrainingModuleName(e) {
        let newTraining = this.state.newTraining;
        newTraining.moduleName = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    onChangeTrainingIssueDate(e) {
        let newTraining = this.state.newTraining;
        newTraining.issueDate = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    onChangeTrainingRefreshDate(e) {
        let newTraining = this.state.newTraining;
        newTraining.refreshDate = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    onChangeTrainingMessage(e) {
        let newTraining = this.state.newTraining;
        newTraining.message = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    onChangeTrainingTopic(e) {
        let newTraining = this.state.newTraining;
        newTraining.topic = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    onChangeTrainingLocation(e) {
        let newTraining = this.state.newTraining;
        newTraining.loacation = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    onChangeType(e) {
        let newTraining = this.state.newTraining;
        newTraining.type = e.target.value;
        this.setState({ newTraining: newTraining });
    }

    validate() {
        let status = true;
        if (this.state.newTraining.name == null) {
            this.setState({ nameError: "please fill up the field" });
            console.log(this.state.nameError);
            status = false;
        }
        // if ( this.state.newTraining.trainerName == null) {
        //     this.setState({ trainerNameError: "please fill up the field" });
        //     console.log(this.state.trainerNameError)
        //     status = false;
        // }

        if (this.state.newTraining.type == 0) {
            this.setState({ typeError: "please fill up the field" });
            console.log(this.state.typeError);
            status = false;
        }
        if (this.state.newTraining.issueDate == null) {
            this.setState({ refreshDateError: "please fill up the field" });
            status = false;
        }
        if (this.state.newTraining.loacation == null) {
            this.setState({ loacationError: "please fill up the field" });
            console.log(this.state.loacationError);
            status = false;
        }
        return status;
    }

    handleInputChange = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
        });
    };
    handleContentFileInputChange = (event) => {
        this.setState({
            selectedContentFile: event.target.files[0],
        });
    };

    submitFile(type) {
        const data = new FormData();

        data.append("fileToUpload", this.state.selectedContentFile);

        let url = "https://iclm.5techg.com/api/fileUpload/";

        axios.post(url, data).then((res) => {
            // then print response status
            this.setState({ activeDocPath: res.data }, this.onSubmit);
        });
    }

    onSubmit(e) {
        if (this.validate()) {
            let url = BASE_URL;
            const query =
                'INSERT INTO trainingMaster (name, trainerId, moduleNo, moduleName, topic, type, issueDate, location, filePath) values("' +
                this.state.newTraining.name +
                '","' +
                this.state.newTraining.newTrainertId +
                '","' +
                this.state.newTraining.moduleNo[1] +
                '","' +
                this.state.newTraining.moduleNo[0] +
                '","' +
                this.state.newTraining.topic +
                '","' +
                this.state.newTraining.type +
                '","' +
                this.state.newTraining.issueDate +
                '","' +
                this.state.newTraining.loacation +
                '","' +
                this.state.activeDocPath +
                '") ';
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            console.log('datasssss',this.state.newTraining)
            axios
                .post(url, data)
                .then((res) => {
                    toast("new training added!");
                    setTimeout(this.refresh, 1000);
                })
                .catch((error) => {
                    toast.error("Something Went Wrong!");
                });

            this.setState({
                name: "",
                trainerName: "",
                auditNumber: "",
                type: "",
                refreshDate: "",
                moduleNo: "",
                moduleName: "",
                message: "",
                topic: "",
                location: "",
            });
        }
    }

    submitTrainingUpdate(e) {
        let url = BASE_URL;
        const query =
            'UPDATE trainingMaster SET name="' +
            this.state.currentTraining_trainingName +
            '",topic="' +
            this.state.currentTraining_topicName +
            '",moduleNo="' +
            this.state.currentTraining_moduleNo[1] +
            '",moduleName="' +
            this.state.currentTraining_moduleNo[0] +
            '",trainerId="' +
            this.state.currentTraining_trainerId +
            '",issueDate="' +
            this.state.currentTraining_trainingDate +
            '",type="' +
            this.state.currentTraining_type +
            '", location="' +
            this.state.currentTraining_location +
            '"  WHERE id=' +
            this.state.currentTraining_id +
            "";
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("training updated successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });

        this.setState({ name: "", trainerName: "", type: "", message: "" });
    }

    redirectToParticipantasPage = (id) => {
    <Link to={`/participants/${id}`}></Link>
    }

    submitTrainingDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE trainingMaster SET status = -1 where id = ${id};`;
       

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("trainings deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {});

        this.setState({
            name: "",
            trainerName: "",
            startDate: "",
            endDate: "",
            type: "",
            refreshDate: "",
            message: "",
            topic: "",
            location: "",
        });
    }

    fetchTrainingssData() {
        let url = BASE_URL;
        const query = `SELECT tgm.*, tm.firstName as tmname FROM trainingMaster as tgm inner join trainerMaster as tm where tgm.trainerId = tm.id AND tgm.status=1 `;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ trainings: res.data });
            })
            .catch((err) => {
                console.log("coueses data error: ", err);
            });
    }

    fetchTrainersData() {
        let url = BASE_URL;
        const query = `select * from trainerMaster where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ trainers: res.data });
            })
            .catch((err) => {
                console.log("trainers data error: ", err);
            });
    }
    fetchCourcesData() {
        let url = BASE_URL;
        const query = `select * from courses;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
      
        axios
            .post(url, data)
            .then((res) => {
                this.setState({ courses: res.data });
            })
            .catch((err) => {
                console.log("courses data error: ", err);
            });
    }

    componentDidMount() {
        this.fetchTrainingssData();
        this.fetchTrainersData();
        this.fetchCourcesData()
    }

    componentDidUpdate() {
        $(document).ready(function () {
            $("#datatable-keytable")
                .DataTable({
                    bSort: true,
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    dom: "lBfrtip",
                    buttons: ["copy", "csv", "excel", "print"],
                    lengthMenu: [
                        50, 100, 500, 1000, 2000, 5000, 10000, 50000, 100000,
                    ],
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderTrainingsData() {
        const trainings = this.state.trainings;
        if (trainings == null) {
            return null;
        }

        return trainings.map((training) => {
            // define user type
            let trainingType = ["Metro", "Construction"][training.type - 1];
            return (
                <tr>
                    <td>{training.id}</td>
                    <td>{training.name}</td>
                    <td>{training.tmname}</td>
                    <td>{training.moduleNo}</td>
                    <td>{training.moduleName}</td>
                    <td>{trainingType}</td>
                    <td>
                        {moment(training.refresherTrainingDate).format(
                            "DD / MM / YYYY"
                        )}
                    </td>
                    <td>{training.location}</td>

                    <td>
                        <a
                            href={`${training.filePath}`}
                            target="_blank"
                            class="btn btn-primary btn-sm mx-1"
                            // download
                        >
                            <i class="fas fa-eye"></i>
                        </a>
                    </td>

                    <td className="" style={{ width: "180px" }}>
                        <button
                            class="btn btn-primary btn-sm mr-1"
                            onClick={() => {
                                this.setState({
                                    currentTraining_id: training.id,
                                    currentTraining_trainingName: training.name,
                                    currentTraining_trainerName:
                                        training.tmname,
                                    currentTraining_moduleNo: training.moduleNo,
                                    currentTraining_moduleName:training.moduleName,
                                    currentTraining_topicName:
                                        training.topic,
                                    currentTraining_type: training.type,
                                    currentTraining_issueDate: moment(
                                        training.issueDate
                                    ).format("DD / MM / YYYY"),
                                    currentTraining_refreshDate: moment(
                                        training.refresherTrainingDate
                                    ).format("DD / MM / YYYY"),
                                    currentTraining_location: training.location,

                                    viewModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-primary btn-sm mr-1"
                            onClick={(e) => {
                              console.log('edd',training.moduleNo);
                                this.setState({
                                    currentTraining_id: training.id,
                                    currentTraining_trainingName: training.name,
                                    currentTraining_topicName: training.topic,
                                    currentTraining_trainerId:
                                        training.trainerId,
                                    currentTraining_moduleNo: training.moduleNo,
                                    currentTraining_moduleName:training.moduleName,
                                    currentTraining_type: training.type,

                                    currentTraining_trainingDate:
                                        training.issueDate.split("T")[0],
                                    currentTraining_location: training.location,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete trainer?"
                                    )
                                ) {
                                    this.submitTrainingDelete(training.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                        <Link to={`/participants/${training.id}`}>
                        <button
                            class="btn btn-info btn-sm ml-1"
                        >
                            <i class="fas fa-user-alt"></i>
                        </button>
                        </Link>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View trainings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Topic Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTraining_trainingName}
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_trainingName:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Trainer Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentTraining_trainerName}
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_trainerName:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Module Number:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={this.state.currentTraining_moduleNo}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Module Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={this.state.currentTraining_moduleName}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Type:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={
                                    this.state.currentTraining_type == 1
                                        ? "Metro"
                                        : "Construction"
                                }
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_type: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Training Date:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentTraining_issueDate}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_issueDate:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                refresh Date:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentTraining_refreshDate}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_refreshDate:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Location:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentTraining_location}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_location:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
        
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="message-text"
                                className="col-form-label"
                            >
                                Topic Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="recipient-name"
                                value={this.state.currentTraining_trainingName}
                                onChange={(e) => {
                                    this.setState({
                                      currentTraining_trainingName:
                                            e.target.value,
                                    });
                                }}
                            />
                            <p
                                style={{
                                    color: "red",
                                }}
                            >
                                {this.state.nameError}
                            </p>
                        </div>
                        <FormControl
                            style={{
                                minWidth: "250px",
                                marginTop: "-1em",
                            }}
                            className="mr-2 mb-2 "
                        >
                            <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                            >
                                Trainer name:
                            </label>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                options={
                                    this.state.trainers != null
                                        ? this.state.trainers.map(
                                              (item) =>
                                                  item.id +
                                                  ", " +
                                                  item.firstName
                                          )
                                        : []
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="party name"
                                        className="form-control"
                                        variant="outlined"
                                        size="small"
                                        value={this.state.trainerName}
                                        style={{
                                            width: "450px",
                                        }}
                                    />
                                )}
                                onChange={(event, value) => {
                                    console.log(value);
                                    if (value != null && value.length > 2) {
                                        this.setState({
                                            currentTraining_trainerId: Number(
                                                value[0]
                                            ),
                                        });
                                    } else {
                                        this.setState({
                                            newTrainertId: null,
                                            newTrainerName: "",
                                        });
                                    }
                                }}
                            />
                            <p
                                style={{
                                    color: "red",
                                }}
                            >
                                {this.state.trainerNameError}
                            </p>
                        </FormControl>

                        <FormControl
                            style={{
                                minWidth: "250px",
                                marginTop: "-1em",
                            }}
                            className="mr-2 mb-2 "
                        >
                            <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                            >
                                Module No:
                            </label>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                options={
                                    this.state.courses != null
                                        ? this.state.courses.map(
                                              (item) =>
                                                  item.moduleName +
                                                  ", " +
                                                  item.moduleNo
                                          )
                                        : []
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="party name"
                                        className="form-control"
                                        variant="outlined"
                                        size="small"
                                        value={this.state.moduleNo}
                                        style={{
                                            width: "450px",
                                        }}
                                    />
                                )}
                                onChange={(event, value) => {
                                    if (value != null && value.length) {
                                      var data= value.split(",");
                                        this.setState({
                                            currentTraining_moduleNo: (
                                              data
                                            ),
                                        });
                                    } else {
                                        this.setState({
                                            newCourceId: null,
                                            moduleName: "",
                                        });
                                    }
                                }}
                            />
                            <p
                                style={{
                                    color: "red",
                                }}
                            >
                                {this.state.moduleNoError}
                            </p>
                        </FormControl>
                     
                        <div className="form-group">
                            <label>Type</label>
                            <select
                                class="form-control select2"
                                style={{
                                    width: "100%",
                                }}
                                value={this.state.currentTraining_type}
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_type: e.target.value,
                                    });
                                }}
                            >
                                <option name="type" value="1">
                                    Metro
                                </option>
                                <option name="type" value="2">
                                    Construction
                                </option>
                            </select>
                            <p
                                style={{
                                    color: "red",
                                }}
                            >
                                {this.state.typeError}
                            </p>
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                            >
                                Training Date:
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                value={this.state.currentTraining_trainingDate}
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_trainingDate:
                                            e.target.value,
                                    });
                                }}
                            />
                            <p
                                style={{
                                    color: "red",
                                }}
                            >
                                {this.state.refreshDateError}
                            </p>
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                            >
                                Location:
                            </label>
                            <input
                                type="text"
                                name="message"
                                className="form-control"
                                id="recipient-message"
                                value={this.state.currentTraining_location}
                                onChange={(e) => {
                                    this.setState({
                                        currentTraining_location:
                                            e.target.value,
                                    });
                                }}
                            />
                            <p
                                style={{
                                    color: "red",
                                }}
                            >
                                {this.state.loacationError}
                            </p>
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                            >
                                Upload Attendance File:
                            </label>
                            <TextField
                                name="fileToUpload"
                                size="small"
                                type="file"
                                variant="outlined"
                                className="mr-3 ml-3 mt-1 inputField"
                                onChange={this.handleContentFileInputChange}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            this.submitTrainingUpdate(e);
                            this.setState({ updateModal: false });
                        }}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2 mr-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-user-plus"></i>
                                                </span>
                                                &nbsp;Add New Training
                                            </button>

                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New Training
                                                                {/*  */}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Topic
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainingName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .nameError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <FormControl
                                                                    style={{
                                                                        minWidth:
                                                                            "250px",
                                                                        marginTop:
                                                                            "-1em",
                                                                    }}
                                                                    className="mr-2 mb-2 "
                                                                >
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Trainer
                                                                        name:
                                                                    </label>
                                                                    <Autocomplete
                                                                        id="free-solo-demo"
                                                                        freeSolo
                                                                        options={
                                                                            this
                                                                                .state
                                                                                .trainers !=
                                                                            null
                                                                                ? this.state.trainers.map(
                                                                                      (
                                                                                          item
                                                                                      ) =>
                                                                                          item.id +
                                                                                          ", " +
                                                                                          item.firstName
                                                                                  )
                                                                                : []
                                                                        }
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <TextField
                                                                                {...params}
                                                                                // label="party name"
                                                                                className="form-control"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .trainerName
                                                                                }
                                                                                style={{
                                                                                    width: "450px",
                                                                                }}
                                                                            />
                                                                        )}
                                                                        onChange={(
                                                                            event,
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                value !=
                                                                                    null &&
                                                                                value.length >
                                                                                    2
                                                                            ) {
                                                                                this.state.newTraining.newTrainertId =
                                                                                    Number(
                                                                                        value[0]
                                                                                    );
                                                                              
                                                                            } else {
                                                                                this.setState(
                                                                                    {
                                                                                        newTrainertId:
                                                                                            null,
                                                                                        newTrainerName:
                                                                                            "",
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .trainerNameError
                                                                        }
                                                                    </p>
                                                                </FormControl>

                                                                <FormControl
                                                                    style={{
                                                                        minWidth:
                                                                            "250px",
                                                                        marginTop:
                                                                            "-1em",
                                                                    }}
                                                                    className="mr-2 mb-2 "
                                                                >
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Module Number:
                                                                    </label>
                                                                    <Autocomplete
                                                                        id="free-solo-demo"
                                                                        freeSolo
                                                                        options={
                                                                            this
                                                                                .state
                                                                                .courses !=
                                                                            null
                                                                                ? this.state.courses.map(
                                                                                      (
                                                                                          item
                                                                                      ) =>
                                                                                          item.moduleName +
                                                                                          ", " +
                                                                                          item.moduleNo
                                                                                  )
                                                                                : []
                                                                        }
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <TextField
                                                                                {...params}
                                                                                // label="party name"
                                                                                className="form-control"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .moduleNo
                                                                                }
                                                                                style={{
                                                                                    width: "450px",
                                                                                }}
                                                                            />
                                                                        )}
                                                                        onChange={(
                                                                            event,
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                value !=
                                                                                    null &&
                                                                                value.length 
                                                                            ) {
                                                                              var splidData = value.split(",");
                                                                                this.state.newTraining.moduleNo =
                                                                                    (
                                                                                      splidData
                                                                                    );
                                                                              
                                                                            } else {
                                                                                this.setState(
                                                                                    {
                                                                                        newCourceId:
                                                                                            null,
                                                                                        newCoursesName:
                                                                                            "",
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .moduleNoError
                                                                        }
                                                                    </p>
                                                                </FormControl>

                                                               
{/*                                                                 
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Module
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="moduleName"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainingModuleName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .auditNumberError
                                                                        }
                                                                    </p>
                                                                </div> */}

                                                                <div className="form-group">
                                                                    <label>
                                                                        Type
                                                                    </label>
                                                                    <select
                                                                        class="form-control select2"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeType(
                                                                                e
                                                                            );
                                                                        }}
                                                                    >
                                                                        <option
                                                                            name="type"
                                                                            value="1"
                                                                        >
                                                                            Metro
                                                                        </option>
                                                                        <option
                                                                            name="type"
                                                                            value="2"
                                                                        >
                                                                            Construction
                                                                        </option>
                                                                    </select>
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .typeError
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Training
                                                                        Date:
                                                                    </label>
                                                                    <input
                                                                        type="date"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainingIssueDate(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .refreshDateError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Location:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="message"
                                                                        className="form-control"
                                                                        id="recipient-message"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeTrainingLocation(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .loacationError
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Upload
                                                                        Attendance
                                                                        File:
                                                                    </label>
                                                                    <TextField
                                                                        name="fileToUpload"
                                                                        size="small"
                                                                        type="file"
                                                                        variant="outlined"
                                                                        className="mr-3 ml-3 mt-1 inputField"
                                                                        onChange={
                                                                            this
                                                                                .handleContentFileInputChange
                                                                        }
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.submitFile(
                                                                        "content"
                                                                    );
                                                                }}
                                                                // disabled={
                                                                //     !this.state.newTraining.topic ||
                                                                //     !this.state.newTraining.trainerName ||
                                                                //     !this.state.newTraining.auditNumber ||
                                                                //     !this.state.newTraining.type ||
                                                                //     !this.state.newTraining.refreshDate ||
                                                                //     !this.state.newTraining.loacation
                                                                // }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for update  starts here*/}

                                        {/* modal for update ends here */}
                                        <table
                                            id="datatable-keytable"
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Topic Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Trainer Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Module No{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Module Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        type{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Training date{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Location{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Attendane{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>

                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderTrainingsData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}