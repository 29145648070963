import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";
import { Card } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import LinearProgress from "@material-ui/core/LinearProgress";
import "react-toastify/dist/ReactToastify.css";

import "../login/Login.css";
//API handling components
import { BASE_URL } from "../common/global";
// import background from "./assets/background.jpg";

const axios = require("axios");

// const data = { userName: "admin", password: "admin" };

function LoginForm() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const Authenticate = (_password, _id, _userName, _role) => {
    if (_password == password) {
        // setId(_id);
        // setName(_name);
        // setType(_type);


        localStorage.setItem("id", _id);
        parseInt(localStorage.id);
        localStorage.setItem("userName", _userName);
        localStorage.setItem("role", _role);
        parseInt(localStorage.type);


        if(_role == 1){
            toast("Login Succesfull");
            history.push("/dashboard");
        }
        else {
            toast("Login Succesfull");
            history.push("/dashboard");
        }

    }
}

const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let url = BASE_URL;

    //production
    const query = `SELECT id,firstName,lastName,userName,password,role from loginMaster where userName like '${userName}' AND password like '${password}'`;
    console.log(query)


    let data = {
        crossDomain: true,
        crossOrigin: true,
        query: query,
    };
    axios
        .post(url, data)
        .then((res) => {
            if (res.data.length > 0) {
                Authenticate(res.data[0].password, res.data[0].id, res.data[0].userName, res.data[0].role);
                window.location.reload(false);

            } else {
                toast("Incorrect Username and password");
                setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log("HTTP request error: ", err);
        });

};
 

  // useEffect(() => {
  //   const auth = JSON.parse(localStorage.getItem("auth"));
  //   console.log({ auth });
  //   if (auth?.isAuthenticated) {
  //     history.push("/dashboard");
  //   }
  // });

  return (
    <div className="wrapper background text-center" style={{marginTop:'5em'}}>
      
      <h2 className="heading pb-4"><b>ICLM</b></h2>
      <Card
        className="pt-5 pb-5 pl-3 pr-3 w-25 login-temp"
        style={{ boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)",margin:'auto' }}
      >
        <div className="text-center mb-3">
      
          <h5 className="sub-head">Admin Login</h5>
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label  style={{float:'left'}}>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username here"
             
              onChange={(e) => setUserName(e.target.value)}
              style={{ fontSize: "15px" }}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label style={{float:'left'}}>Password</Form.Label>
            <Form.Control
              type="password"
              
              placeholder="Enter your password here"
              onChange={(e) => setPassword(e.target.value)}
              style={{ fontSize: "15px" }}
              required
            />
          </Form.Group>
          <Button variant="primary btn-block" type="submit">
            Log In
          </Button>
        </Form>
        {isLoading ? <LinearProgress color="secondary" /> : null}
      </Card>

      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
    </div>
  );
}

export default LoginForm;
