import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "../common/global.js";
//react toast
import { toast } from "react-toastify";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,

            newUser: {
                firstname: null,
                lastName: null,
                userName: null,
                password: null,
                firstnameError: null,
                lastNameError: null,
                userNameError: null,
                passwordError: null,
                typeError: null,

                type: 1,
                status: null,
            },

            currentUser_id: null,
            currentUser_firstname: null,
            currentUser_lastName: null,
            currentUser_userName: null,
            currentUser_password: null,
            currentUser_type: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeUserFirstName(e) {
        let newUser = this.state.newUser;
        newUser.firstname = e.target.value;
        this.setState({ newUser: newUser });
    }

    onChangeUserLastName(e) {
        let newUser = this.state.newUser;
        newUser.lastName = e.target.value;
        this.setState({ newUser: newUser });
    }

    onChangeUserPassword(e) {
        let newUser = this.state.newUser;
        newUser.password = e.target.value;
        this.setState({ newUser: newUser });
    }

    onChangeUserUsername(e) {
        let newUser = this.state.newUser;
        newUser.userName = e.target.value;
        this.setState({ newUser: newUser });
    }

    onChangeType(e) {
        let newUser = this.state.newUser;
        newUser.type = e.target.value;
        this.setState({ newUser: newUser });
    }

    validate() {
        if (this.state.newUser.firstname == null) {
            this.setState({ firstnameError: "please fill up the field" });
            console.log(this.state.newUser.firstnameError);
            return false;
        }
        if (this.state.newUser.lastName == null) {
            this.setState({ lastNameError: "please fill up the field" });
            console.log(this.state.newUser.lastNameError);
            return false;
        }
        if (this.state.newUser.userName == null) {
            this.setState({ userNameError: "please fill up the field" });
            console.log(this.state.newUser.userNameError);
            return false;
        }
        if (this.state.newUser.password == null) {
            this.setState({ passwordError: "please fill up the field" });
            console.log(this.state.newUser.passwordError);
            return false;
        }
        if (this.state.newUser.type == null) {
            this.setState({ typeError: "please fill up the field" });
            console.log(this.state.newUser.typeError);
            return false;
        }

        return true;
    }

    onSubmit() {
        if (this.validate()) {
            let url = BASE_URL;
            const query =
                'INSERT INTO loginMaster (firstName, lastName, userName, password, role) values("' +
                this.state.newUser.firstname +
                '","' +
                this.state.newUser.lastName +
                '", "' +
                this.state.newUser.userName +
                '", "' +
                this.state.newUser.password +
                '","' +
                this.state.newUser.type +
                '") ';
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    toast("new user added!");
                    setTimeout(this.refresh, 1000);
                })
                .catch((error) => {
                    toast.error("Something Went Wrong!");
                });

            this.setState({
                firstname: "",
                lastName: "",
                userName: "",
                password: "",
                type: "",
            });
        }
    }

    submitUserUpdate(e) {
        let url = BASE_URL;
        const query =
            'UPDATE loginMaster SET firstName="' +
            this.state.currentUser_firstname +
            '", lastName="' +
            this.state.currentUser_lastName +
            '",  userName ="' +
            this.state.currentUser_userName +
            '", password ="' +
            this.state.currentUser_password +
            '" WHERE id=' +
            this.state.currentUser_id +
            "";

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("user updated successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });

        this.setState({
            firstname: "",
            lastName: "",
            userName: "",
            password: "",
        });
    }

    submitUserDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE loginMaster SET status = -1 where id = ${id};`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("user deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });

        this.setState({
            firstname: "",
            lastName: "",
            userName: "",
            password: "",
        });
    }

    fetchUsersData() {
        let url = BASE_URL;
        const query = `SELECT * FROM loginMaster where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ users: res.data });
            })
            .catch((err) => {
                console.log("users data error: ", err);
            });
    }

    componentDidMount() {
        this.fetchUsersData();
    }

    componentDidUpdate() {
        $(document).ready(function () {
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                    exportOptions: {
                        columns: "th:not(:last-child)",
                    },
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderUsersData() {
        const users = this.state.users;

        if (users == null) {
            return null;
        }

        return users.map((user) => {
            // define user type
            let loginType = ["Super admin", "admin", "user "][user.role - 1];
            return (
                <tr>
                    <td>{user.id}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.userName}</td>
                    <td>{user.password}</td>
                    <td>{loginType}</td>
                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState({
                                    currentUser_id: user.id,
                                    currentUser_firstname: user.firstName,
                                    currentUser_lastName: user.lastName,
                                    currentUser_userName: user.userName,
                                    currentUser_password: user.password,
                                    currentUser_type: user.role,
                                    viewModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={(e) => {
                                this.setState({
                                    currentUser_id: user.id,
                                    currentUser_firstname: user.firstName,
                                    currentUser_lastName: user.lastName,
                                    currentUser_userName: user.userName,
                                    currentUser_password: user.password,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete user?"
                                    )
                                ) {
                                    this.submitUserDelete(user.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                First Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentUser_firstname}
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_firstname: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Last Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentUser_lastName}
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_lastName: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                UserName:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentUser_userName}
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_userName: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Password:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentUser_password}
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_password: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Type:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={
                                    this.state.currentUser_type == 1
                                        ? "Super Admin"
                                        : this.state.currentUser_type == 2
                                        ? "Admin"
                                        : "User"
                                }
                                className="form-control"
                                id="siteEngineers"
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_type: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                First Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentUser_firstname}
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_firstname: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Last Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentUser_lastName}
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_lastName: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                UserName:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentUser_userName}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_userName: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Password:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentUser_password}
                                className="form-control"
                                id="siteEngineers"
                                placeholder="1, 2, 3"
                                onChange={(e) => {
                                    this.setState({
                                        currentUser_password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            this.submitUserUpdate(e);
                            this.setState({ updateModal: false });
                        }}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-user-plus"></i>
                                                </span>
                                                &nbsp;Add New User
                                            </button>
                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New User
                                                                {/*  */}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        First
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        required
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeUserFirstName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .firstnameError
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="message-text"
                                                                        className="col-form-label"
                                                                    >
                                                                        Last
                                                                        Name:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeUserLastName(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .lastNameError
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        Username:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeUserUsername(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .userNameError
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="recipient-name"
                                                                        className="col-form-label"
                                                                    >
                                                                        password:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        className="form-control"
                                                                        id="recipient-name"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeUserPassword(
                                                                                e
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .passwordError
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>
                                                                        Type
                                                                    </label>
                                                                    <select
                                                                        class="form-control select2"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.onChangeType(
                                                                                e
                                                                            );
                                                                        }}
                                                                    >
                                                                        <option
                                                                            name="type"
                                                                            value="1"
                                                                        >
                                                                            Super
                                                                            Admin
                                                                        </option>
                                                                        <option
                                                                            name="type"
                                                                            value="2"
                                                                        >
                                                                            Admin
                                                                        </option>
                                                                        <option
                                                                            name="type"
                                                                            value="3"
                                                                        >
                                                                            User
                                                                        </option>
                                                                    </select>
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .typeError
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    this.onSubmit();
                                                                }}
                                                                // disabled={
                                                                //     !this.state.newUser.firstname ||
                                                                //     !this.state.newUser.lastName ||
                                                                //     !this.state.newUser.userName ||
                                                                //     !this.state.newUser.password ||
                                                                //     !this.state.newUser.type
                                                                // }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for update  starts here*/}

                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped table-sortable"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>id</th>
                                                    <th>
                                                        First Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Last name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Username{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Password{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Login Type{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderUsersData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
