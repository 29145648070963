import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";
import moment from "moment";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: null,
            newCollection: {
                CollectionId: null,
                date: moment(new Date()).format("YYYY-MM-DD"),
                invoiceNo: null,
                gstNo: null,
                client: null,
                customerName: null,
                address: null,
                billAmt: null,
                status: null,
            },
            dateError: null,
            invoiceNoError: null,
            gstNoError: null,
            clientError: null,
            customerNameError: null,
            addressError: null,
            billAmtError: null,

            currentCollection_id: null,
            currentCollection_date: null,
            currentCollection_invoiceNo: null,
            currentCollection_gstNo: null,
            currentCollection_client: null,
            currentCollection_customerName: null,
            currentCollection_address: null,
            currentCollection_billAmt: null,
            currentCollection_totalPaid: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeDate(e) {
        let newCollection = this.state.newCollection;
        newCollection.date = e.target.value;
        this.setState({ newCollection: newCollection });
    }
    onChangeGstNo(e) {
        let newCollection = this.state.newCollection;
        newCollection.gstNo = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeInvoiceNO(e) {
        let newCollection = this.state.newCollection;
        newCollection.invoiceNo = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeCustomerName(e) {
        let newCollection = this.state.newCollection;
        newCollection.customerName = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeAddress(e) {
        let newCollection = this.state.newCollection;
        newCollection.address = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeBillAmt(e) {
        let newCollection = this.state.newCollection;
        newCollection.billAmt = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    onChangeClient(e) {
        let newCollection = this.state.newCollection;
        newCollection.client = e.target.value;
        this.setState({ newCollection: newCollection });
    }

    validate() {
        let status = true;
        if (this.state.newCollection.date == null) {
            this.setState({ dateError: "please fill up the field" });
            console.log(this.state.dateError);
            status = false;
        }
        if (this.state.newCollection.invoiceNo == null) {
            this.setState({ invoiceNoError: "please fill up the field" });
            console.log(this.state.invoiceNoError);
            status = false;
        }
        if (this.state.newCollection.gstNo == null) {
            this.setState({ gstNoError: "please fill up the field" });
            console.log(this.state.gstNoError);
            status = false;
        }
        if (this.state.newCollection.client == null) {
            this.setState({ clientError: "please fill up the field" });
            console.log(this.state.clientError);
            status = false;
        }
        if (this.state.newCollection.customerName == null) {
            this.setState({ customerNameError: "please fill up the field" });
            console.log(this.state.customerNameError);
            status = false;
        }
        if (this.state.newCollection.address == null) {
            this.setState({ addressError: "please fill up the field" });
            console.log(this.state.addressError);
            status = false;
        }
        if (this.state.newCollection.billAmt == null) {
            this.setState({ billAmtError: "please fill up the field" });
            console.log(this.state.billAmtError);
            status = false;
        }
        return status;
    }

    onSubmit(e) {
        if (this.validate()) {
            let url = BASE_URL;
            const query = `INSERT INTO collection (date, invoiceNo, gstNo, customerName, address, billAmount, status) values(
            '${this.state.newCollection.date}',
            '${this.state.newCollection.invoiceNo}',
            '${this.state.newCollection.gstNo}',
            '${this.state.newCollection.customerName}',
            '${this.state.newCollection.address}',
            '${this.state.newCollection.billAmt}',
            1
        ) `;
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    toast("new collection added!");
                    setTimeout(this.refresh, 1000);
                })
                .catch((error) => {
                    toast("Something went wrong");
                });

            this.setState({
                firstName: "",
                lastName: "",
                address: "",
                qualification: "",
                email: "",
                mobileNo: "",
            });
        }
    }

    submitCollectionUpdate(e) {
        let url = BASE_URL;
        const query = `UPDATE collection SET 
            paid = ${this.state.currentCollection_totalPaid},
            pending = ${
                this.state.currentCollection_billAmt -
                this.state.currentCollection_totalPaid
            }
              WHERE id = ${this.state.currentCollection_id}
         `;

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("collection updated successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast("Something went wrong");
            });

        this.setState({
            firstName: "",
            lastName: "",
            qualification: "",
            email: "",
            mobileNo: "",
            address: "",
        });
    }

    submitTrainerDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE collection SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("collection deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast("Something went wrong");
            });

        this.setState({
            firstName: "",
            lastName: "",
            address: "",
            qualification: "",
            email: "",
            mobileNo: "",
        });
    }

    fetchCollectionsData() {
        let url = BASE_URL;
        const query = `SELECT * FROM collection where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ collections: res.data });
                console.log(res.data);
            })
            .catch((err) => {});
    }

    componentDidMount() {
        this.fetchCollectionsData();
    }
    componentDidUpdate() {
        $(document).ready(function () {
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderCollectionsData() {
        const collections = this.state.collections;

        if (collections == null) {
            return null;
        }

        return collections.map((collection) => {
            return (
                <tr>
                    <td>{collection.id}</td>
                    <td>{moment(collection.date).format("YYYY-MM-DD")}</td>
                    <td>{collection.invoiceNo}</td>
                    <td>{collection.gstNo}</td>
                    <td>{collection.customerName}</td>
                    <td>{collection.address}</td>
                    <td>{collection.billAmount}</td>
                    <td>{collection.paid}</td>
                    <td>{collection.pending}</td>
                    <td className="">
                        

                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={(e) => {
                                this.setState({
                                    currentCollection_id: collection.id,
                                    currentCollection_date: collection.date,
                                    currentCollection_invoiceNo:
                                        collection.invoiceNo,
                                    currentCollection_gstNo: collection.gstNo,
                                    currentCollection_customerName:
                                        collection.customerName,
                                    currentCollection_address:
                                        collection.address,
                                    currentCollection_billAmt:
                                        collection.billAmount,
                                    updateModal: true,
                                });
                            }}
                        >
                            <i class="fas fa-user-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete collection?"
                                    )
                                ) {
                                    this.submitTrainerDelete(collection.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Date:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentCollection_date}
                                onChange={(e) => {
                                    this.setState({
                                        currentCollection_date: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Invoice No:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentCollection_invoiceNo}
                                onChange={(e) => {
                                    this.setState({
                                        currentCollection_invoiceNo:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                GST No:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={this.state.currentCollection_gstNo}
                                onChange={(e) => {
                                    this.setState({
                                        currentCollection_gstNo: e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Customer Name:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                value={
                                    this.state.currentCollection_customerName
                                }
                                onChange={(e) => {
                                    this.setState({
                                        currentCollection_customerName:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label
                                htmlFor="siteAddress"
                                className="col-form-label"
                            >
                                Address:
                            </label>
                            <textarea
                                className="form-control"
                                name="address"
                                value={this.state.currentCollection_address}
                                onChange={(e) => {
                                    this.setState({
                                        currentCollection_address:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                                id="siteAddress"
                            />
                        </div>

                        <div className="form-group">
                            <label
                                htmlFor="siteEngineers"
                                className="col-form-label"
                            >
                                Bill Amt:
                            </label>
                            <input
                                type="text"
                                name="Engineers"
                                value={this.state.currentCollection_billAmt}
                                className="form-control"
                                id="siteEngineers"
                                onChange={(e) => {
                                    this.setState({
                                        currentCollection_billAmt:
                                            e.target.value,
                                    });
                                }}
                                readOnly
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label
                                htmlFor="siteName"
                                className="col-form-label"
                            >
                                Total paid:
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="siteName"
                                onChange={(e) => {
                                    this.setState({
                                        currentCollection_totalPaid:
                                            e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            this.submitCollectionUpdate(e);
                            this.setState({ updateModal: false });
                        }}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Date{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Invoice No{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        GST No.{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Customer Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Address{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Bill Amt{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Paid{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Pending{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>

                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderCollectionsData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
