import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "../common/global.js";
//react toast
import { toast } from "react-toastify";
import iclmLogo from "./assets/iclmLogo.jpeg";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import {
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class InvoiceHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: null,
            invoiceItems: [],
            id: 1,
            invoiceNo: 1,
            companyName: null,
            mobileNo: null,
            total: null,
            date: null,
            gstNo: null,
            companyAddress: null,
            trainingAddress: null,
            sub: null,
            emailId: null,
            grandTotal: null,
            transportationCharges: null,
            gstType: null,

            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }

    onChangeUserName(e) {
        let newCandidate = this.state.newCandidate;
        newCandidate.name = e.target.value;
        this.setState({ newCandidate: newCandidate });
    }

    onChangeUserAddress(e) {
        let newCandidate = this.state.newCandidate;
        newCandidate.address = e.target.value;
        this.setState({ newCandidate: newCandidate });
    }

    onChangeUserPassword(e) {
        let newCandidate = this.state.newCandidate;
        newCandidate.password = e.target.value;
        this.setState({ newCandidate: newCandidate });
    }

    onChangeUserEmail(e) {
        let newCandidate = this.state.newCandidate;
        newCandidate.email = e.target.value;
        this.setState({ newCandidate: newCandidate });
    }

    onChangeUserMobileNo(e) {
        let newCandidate = this.state.newCandidate;
        newCandidate.mobileNo = e.target.value;
        this.setState({ newCandidate: newCandidate });
    }

    onChangeType(e) {
        this.setState({ type: e.target.value });
    }

    delete = (index) => {
        // let itemList = this.state.itemList;
        let itemList = this.state.invoiceItems;

        // update total & balance
        // let total = this.state.total - itemList[index]["amount"];
        // let balance = total + Number(this.state.advance);
        // this.setState({ total: total }, this.calculateTaxes);
        // this.setState({ balance: balance });

        // remove element
        // let updatedList = itemList.filter((item, _index) => {
        //     if (index !== _index) return item;
        // });
        // this.setState({ itemList: updatedList });
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ invoiceItems: updatedList });
    };

    calculateTaxes = () => {
        const total = this.state.total;

        this.setState(
            {
                cgst: Number((total / 100) * 9).toFixed(2),
                sgst: Number((total / 100) * 9).toFixed(2),
                igst: Number((total / 100) * 18).toFixed(2),
            },
            this.calculateGrandTotal
        );
        console.log(total);
    };

    // onSubmit(e) {
    //     let url = BASE_URL;
    //     const query =
    //         'INSERT INTO candidates (name, emailId, address, mobileNo) values("' +
    //         this.state.newCandidate.name +
    //         '","' +
    //         this.state.newCandidate.address +
    //         '", "' +
    //         this.state.newCandidate.email +
    //         '", "' +
    //         this.state.newCandidate.mobileNo +
    //         '") ';
    //     let data = { crossDomain: true, crossOrigin: true, query: query };
    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             console.log(res.data);
    //             toast("new user added!");
    //             setTimeout(this.refresh, 1000);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });

    //     this.setState({ name: "", address: "", email: "", mobileNo: "" });
    // }

    updateInvoice(e) {
        let url = BASE_URL;
        const query =
            'UPDATE invoiceMaster SET companyName="' +
            this.state.companyName +
            '", companyAddress="' +
            this.state.companyAddress +
            '",  trainingAddress ="' +
            this.state.trainingAddress +
            '", gstNo="' +
            this.state.gstNo +
            '", mobileNo="' +
            this.state.mobileNo +
            '", sub="' +
            this.state.sub +
            '", emailId="' +
            this.state.emailId +
            '" WHERE id=' +
            this.state.id +
            "";

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("Invoice updated successfully!");
                this.updateBillList()
                // setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({ companyName: "", companyAddress: "", trainingAddress: "", gstNo: "", mobileNo: "", sub: "", emailId: "" });
    }

    updateBillList = () => {
        let url = BASE_URL;

        // 1.  insert into deliveryMemoList

        const query = `INSERT INTO invoiceItems(invoiceId, itemName, description, sac, qty, rate, uom, amount, status) VALUES(
      ${this.state.invoiceNo},
      '${this.state.billTo}',
      '${this.state.description}',
      ${this.state.sac},
      ${this.state.qty},
      ${this.state.rate},
      '${this.state.uom}',
      ${this.state.total},
      1
    )`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(
                    "insert invoiceItems successfull, index: ",
                );
            })
            .catch((err) => {
                console.log("failed to insert invoiceItems, error: ", err);
            });

    };

    submitUserDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE invoiceMaster SET status = 0 where id = ${id};`;
        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("invoice deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    fetchInvoiceData() {
        let url = BASE_URL;
        const query = `select * from invoiceMaster where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("invoices data: ", res.data);
                this.setState({ invoices: res.data });
            })
            .catch((err) => {
                console.log("invoices data error: ", err);
            });
    }

    fetchInvoiceItemList = (id) => {
        let url = BASE_URL;
        const query = `SELECT it.* FROM invoiceItems as it inner join invoiceMaster as im on it.invoiceId = im.id where it.invoiceId= ${this.state.id}`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("invoiceItems data: ", res.data);
                this.setState({ invoiceItems: res.data });

                // init data table
                // this.initializeDataTable();
            })
            .catch((err) => {
                console.log("invoiceItems fetch error: ", err);
            });
    };

    calculateGrandTotal = () => {
        let grandTotal;
        
        if (this.state.gstType === 1) {
            grandTotal =
                Number(this.state.total) +
                Number(this.state.cgst) +
                Number(this.state.sgst);
        } else {
            grandTotal = Number(this.state.total) + Number(this.state.igst);
        }
        this.setState({ grandTotal: grandTotal.toFixed(2) });
    };

    addItems = () => {
        if (!this.state.billTo) return;
        // let items = this.state.itemList;
        let items = this.state.invoiceItems;
        const ifExists = items.find(
            (item) => item.billTo === this.state.billTo
        );
        if (ifExists) {
            items = items.map((item) => {
                if (item.billTo === this.state.billTo) {
                    return {
                        id: this.state.id,
                        itemName: this.state.billTo,
                        description: this.state.description,
                        qty: this.state.qty,
                        rate: this.state.rate,
                        sac: this.state.sac,
                        uom: this.state.uom,
                        amount:
                            +item.amount + +this.state.rate * +this.state.qty,
                    };
                }
            });
        } else {
            items.push({
                id: this.state.id,
                itemName: this.state.billTo,
                description: this.state.description,
                qty: this.state.qty,
                rate: this.state.rate,
                sac: this.state.sac,
                uom: this.state.uom,
                amount: this.state.rate * this.state.qty,
            });
        }
        // items.push({
        //     particular: this.state.particular,
        //     mark: this.state.mark,
        //     totalBoxes: this.state.totalBoxes,
        //     weightPerBox: this.state.weightPerBox,
        //     weight: this.state.weight,
        //     rate: this.state.rate,
        //     amount: this.state.amount,
        // });

        this.setState({ invoiceItems: items });
        console.log(this.state.invoiceItems);

        // update total & balance
        // let total = Number(this.state.total) + Number(this.state.amount);
        let total = Number(this.state.rate * this.state.qty);
        this.setState({ total: total }, this.calculateTaxes);
        let balance = total - Number(this.state.paid);
        this.setState({ balance: balance });
        // this.calculateTaxes();
    };


    componentDidMount() {
        this.fetchInvoiceData();
    }

    componentDidUpdate() {
        $(document).ready(function () {
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                    exportOptions: {
                        columns: "th:not(:last-child)",
                    },
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderUsersData() {
        const invoices = this.state.invoices;

        if (invoices == null) {
            return null;
        }

        return invoices.map((invoice) => {
            return (
                <tr>
                    <td>{invoice.invoiceNo}</td>
                    <td>{invoice.companyName}</td>
                    <td>{invoice.mobileNo}</td>
                    <td>{invoice.total}</td>
                    <td>
                        {moment(invoice.lastModified).format("DD / MM / YYYY")}
                    </td>
                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState(
                                    {
                                        id: invoice.id,
                                        invoiceNo: invoice.invoiceNo,
                                        companyName: invoice.companyName,
                                        mobileNo: invoice.mobileNo,
                                        total: invoice.total,
                                        date: moment(
                                            invoice.lastModified
                                        ).format("DD / MM / YYYY"),
                                        gstNo: invoice.gstNo,
                                        companyAddress: invoice.companyAddress,
                                        trainingAddress:
                                            invoice.trainingAddress,
                                        sub: invoice.sub,
                                        emailId: invoice.emailId,
                                        grandTotal: invoice.grandTotal,
                                        transportationCharges:
                                            invoice.transportationCharges,
                                        gstType: invoice.gstType,

                                        viewModal: true,
                                    },
                                    this.fetchInvoiceItemList()
                                );
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState(
                                    {
                                        id: invoice.id,
                                        invoiceNo: invoice.invoiceNo,
                                        companyName: invoice.companyName,
                                        mobileNo: invoice.mobileNo,
                                        total: invoice.total,
                                        date: moment(
                                            invoice.lastModified
                                        ).format("DD / MM / YYYY"),
                                        gstNo: invoice.gstNo,
                                        companyAddress: invoice.companyAddress,
                                        trainingAddress:
                                            invoice.trainingAddress,
                                        sub: invoice.sub,
                                        emailId: invoice.emailId,
                                        grandTotal: invoice.grandTotal,
                                        transportationCharges:
                                            invoice.transportationCharges,
                                        gstType: invoice.gstType,

                                        updateModal: true,
                                    },
                                    this.fetchInvoiceItemList
                                );
                            }}
                        >
                            <i class="fas fa-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete user?"
                                    )
                                ) {
                                    this.submitUserDelete(invoice.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="mt-1 p-2 measure"
                        ref={(el) => (this.printComponentRef = el)}
                    >
                        <Row>
                            <Col md={12} className="mx-auto">
                                <Card
                                    className="mt-2 p-0"
                                    style={{
                                        height: "auto",
                                        border: "1px solid black",
                                    }}
                                >
                                    <Card.Header>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5 className="text-center pb-0 mb-0">
                                                    <img
                                                        height={100}
                                                        src={iclmLogo}
                                                    ></img>
                                                    <h6>
                                                        Institute of
                                                        Construction Equipments
                                                        and Lifting Machines
                                                    </h6>
                                                </h5>
                                                <hr />
                                            </div>
                                            <div className="col-12">
                                                <h6 className="text-center">
                                                    Office:- 607/R Square, Veena
                                                    Nagar, Opposite kamgar
                                                    Hospital, LBS Road,
                                                    <br /> Mulund West- 400 080
                                                    <br />{" "}
                                                    Email:iclmconnect@gmail.com,
                                                    www.iclm.org.in, Mobile:
                                                    09321905149
                                                </h6>
                                            </div>
                                        </div>
                                        <hr />

                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: "-1em",
                                            }}
                                        >
                                            <p>
                                                Invoice No.{" "}
                                                <b>{this.state.invoiceNo}</b>
                                            </p>
                                            <p>
                                                Date{" "}
                                                <b>
                                                    {moment(new Date()).format(
                                                        "D / M / YYYY"
                                                    )}
                                                </b>
                                            </p>
                                        </span>
                                        <hr />
                                        <Card.Body
                                            className="pb-3 mb-0"
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <Row>
                                                <Col md={6}>
                                                    <h6
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        Bill To :-{" "}
                                                        <b>
                                                            {
                                                                this.state
                                                                    .companyName
                                                            }
                                                        </b>
                                                    </h6>
                                                </Col>
                                                <Col md={6}>
                                                    <h6
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        Training Add :-{" "}
                                                        <b>
                                                            {
                                                                this.state
                                                                    .trainingAddress
                                                            }
                                                        </b>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <h6
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        Office At :-{" "}
                                                        <b>
                                                            {
                                                                this.state
                                                                    .companyAddress
                                                            }
                                                        </b>
                                                    </h6>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <h6
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        GST NO :-{" "}
                                                        <b>
                                                            {this.state.gstNo}
                                                        </b>
                                                    </h6>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card.Header>

                                    <h5 className="text-center pb-0 mt-2">
                                        <b> TAX INVOICE</b>
                                    </h5>

                                    <Card.Header>
                                        <Card.Body
                                            className="pb-3 mb-0"
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <Row>
                                                <Col md={6}>
                                                    <h6
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        Contact No :-{" "}
                                                        <b>
                                                            {
                                                                this.state
                                                                    .mobileNo
                                                            }
                                                        </b>
                                                    </h6>
                                                </Col>
                                                <Col md={6}>
                                                    <h6
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        sub :-{" "}
                                                        <b>{this.state.sub}</b>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <h6
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        Email Id :-{" "}
                                                        <b>
                                                            {this.state.emailId}
                                                        </b>
                                                    </h6>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card.Header>
                                    <Card.Body className="m-0 pt-0 mt-3">
                                        <Tbl striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Description</th>
                                                    <th>Topic Name</th>
                                                    <th>SAC</th>
                                                    <th>QTY</th>
                                                    <th>Rate</th>
                                                    <th>UOM</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            {this.state.invoiceItems.length >
                                                0 ? (
                                                <tbody>
                                                    {this.state.invoiceItems.map(
                                                        (item, id) => {
                                                            return (
                                                                <tr
                                                                    key={id}
                                                                >
                                                                    <td>
                                                                        {id + 1}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.description
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.itemName
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.qty
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.rate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.sac
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.uom
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amount
                                                                        }
                                                                    </td>
                                                                    {/* <td className="d-print-none" align="center">
                                    <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => this.deleteItem(index)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                                  </td> */}
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                    <br></br>

                                                    <>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td colSpan="2">
                                                                Transportation
                                                                charges
                                                            </td>
                                                            <td></td>

                                                            <td>
                                                                {
                                                                    this.state
                                                                        .transportationCharges
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td colSpan="2">
                                                                Total
                                                            </td>
                                                            <td></td>

                                                            <td>
                                                                {
                                                                    this.state
                                                                        .total
                                                                }
                                                            </td>
                                                        </tr>

                                                        {this.state.gstType ===
                                                            1 ? (
                                                            <>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td colSpan="2">
                                                                        SGST @
                                                                    </td>

                                                                    <td>9%</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td colSpan="2">
                                                                        CGST @
                                                                    </td>

                                                                    <td>9%</td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        ) : (
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td colSpan="2">
                                                                    IGST @
                                                                </td>

                                                                <td>18%</td>
                                                                <td></td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td colSpan="4">
                                                                {" "}
                                                            </td>
                                                            <td colSpan="2">
                                                                Grand Total
                                                            </td>

                                                            <td></td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .grandTotal
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">
                                                                Total in words :{" "}
                                                            </td>

                                                            <td colSpan="2">
                                                                Total (Rounded)
                                                            </td>

                                                            <td></td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .grandTotal
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="8">
                                                            No items added
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Tbl>
                                    </Card.Body>
                                    <Card.Body
                                        style={{ border: "1px solid black" }}
                                    >
                                        <div className="row">
                                            <div className="col-9">
                                                <h6
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    <b>
                                                        GST NO: 27AYTPS6119F1ZV
                                                    </b>
                                                    <br />
                                                    I. TAX PAN No. : AYTPS6119F
                                                    <br />
                                                    Company Bank Details:
                                                    <br />
                                                    A/C Name: Institute of
                                                    Construction Equipments and
                                                    Lifting Machines
                                                    <br />
                                                    Axis Bank, A/C no:
                                                    '917020066522350 , IFSC
                                                    Code: UTIB0000772
                                                    <br />
                                                    I/We hereby certify that
                                                    my/our Registration
                                                    Certificate under the
                                                    Maharashtra Value Added Tax
                                                    Act 2002 is in force on the
                                                    date on which the sale of
                                                    the goods specified in this
                                                    tax invoice is made by me/us
                                                    and that transaction of the
                                                    sale covered by this tax
                                                    invoice has been effected by
                                                    we/us and it shall be
                                                    counted for in the turn over
                                                    ofsales while filling of
                                                    return and the due tax, if
                                                    any payable on thesales has
                                                    been paid or shall be paid.
                                                </h6>
                                            </div>
                                            <div className="col-3">
                                                <h6
                                                    style={{
                                                        marginBottom: "7em",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    For Institute of
                                                    Construction Equipments and
                                                    Lifting Machines
                                                </h6>
                                                <h6
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    Authorised Signatory
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Body>

                                    <div style={{ border: "1px solid black" }}>
                                        <h5>Terms Of Payment :</h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            1) Payment should be made
                                            Immediately against receipt of
                                            invoice
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            2) If payment is not made within 45
                                            days GST credit will be reversed
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            3) Additional 21 % interest will be
                                            charged on non-payment of bill
                                            within 45 days
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            4) Invoice once accepted will not be
                                            entertained for any corrections
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            5) Customers are not entitled to
                                            claim GST credit against this bill
                                            until full amount not paid as
                                            mentioned in Total Amount column.
                                        </h5>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint content={() => this.printComponentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button
                                    onClick={handlePrint}
                                    className="btn btn-primary mt-1 mr-1"
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right" }}
                                // disabled={
                                //     (this.state.partyName ||
                                //         this.state.newPartyName) &&
                                //         this.state.address
                                //         ? false
                                //         : true
                                // }
                                >
                                    Print
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="mt-1 p-2 measure"
                        ref={(el) => (this.printComponentRef = el)}
                    >
                        <Row>
                            <Col md={12} className="mx-auto">
                                <Card
                                    className="mt-2 p-0"
                                    style={{
                                        height: "auto",
                                        border: "1px solid black",
                                    }}
                                >
                                    <Card.Header>
                                        <div className="row">
                                            <div className="col-12">
                                                <h5 className="text-center pb-0 mb-0">
                                                    <img
                                                        height={100}
                                                        src={iclmLogo}
                                                    ></img>
                                                    <h6>
                                                        Institute of
                                                        Construction Equipments
                                                        and Lifting Machines
                                                    </h6>
                                                </h5>
                                                <hr />
                                            </div>
                                            <div className="col-12">
                                                <h6 className="text-center">
                                                    Office:- 607/R Square, Veena
                                                    Nagar, Opposite kamgar
                                                    Hospital, LBS Road,
                                                    <br /> Mulund West- 400 080
                                                    <br />{" "}
                                                    Email:iclmconnect@gmail.com,
                                                    www.iclm.org.in, Mobile:
                                                    09321905149
                                                </h6>
                                            </div>
                                        </div>
                                        <hr />

                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: "-1em",
                                            }}
                                        >
                                            <p>
                                                Invoice No.{" "}
                                                <b>{this.state.invoiceNo}</b>
                                            </p>
                                            <p>
                                                Date{" "}
                                                <b>
                                                    {moment(new Date()).format(
                                                        "D / M / YYYY"
                                                    )}
                                                </b>
                                            </p>
                                        </span>
                                        <hr />
                                        <Card.Body
                                            className="pb-3 mb-0"
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="Office At"
                                                        label="Office At"
                                                        variant="outlined"
                                                        className={"mr-2"}
                                                        value={this.state.companyName}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                companyName: e.target.value,
                                                            })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="Office At"
                                                        label="Office At"
                                                        variant="outlined"
                                                        className={"mr-2"}
                                                        value={this.state.companyAddress}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                companyAddress: e.target.value,
                                                            })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="trainingAddress"
                                                        label="Training Address"
                                                        variant="outlined"
                                                        className="mr-2"
                                                        value={this.state.trainingAddress || ""}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                trainingAddress: e.target.value,
                                                            })
                                                        }
                                                        disabled={!!this.state.partyId}
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <FormControl
                                                        style={{ width: "220px" }}
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        size="small"
                                                    >
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            Bill Type
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    gstType: e.target.value,
                                                                })
                                                            }
                                                            name="gstType"
                                                            value={this.state.gstType}
                                                            size="small"
                                                        >
                                                            <MenuItem value={1}>
                                                                State Central GST
                                                            </MenuItem>
                                                            <MenuItem value={2}>IGST</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="gstin"
                                                        label="GSTIN"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.gstNo}
                                                        onChange={(e) =>
                                                            this.setState({ gstNo: e.target.value })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="mobileNo"
                                                        label="Mobile Number"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.mobileNo}
                                                        onChange={(e) =>
                                                            this.setState({ mobileNo: e.target.value })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="emailId"
                                                        label="Email"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.emailId}
                                                        onChange={(e) =>
                                                            this.setState({ emailId: e.target.value })
                                                        }
                                                        required="true"
                                                        size="small"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="subject"
                                                        label="Subject"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.sub}
                                                        onChange={(e) =>
                                                            this.setState({ sub: e.target.value })
                                                        }
                                                        required="true"
                                                        size="small"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="topic"
                                                        label="Topic Name"
                                                        variant="outlined"
                                                        className={"mr-2 mt-2"}
                                                        value={this.state.billTo}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                billTo: e.target.value,
                                                            })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="description"
                                                        label="Description"
                                                        variant="outlined"
                                                        className={"mr-2 mt-2"}
                                                        value={this.state.description}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                description: e.target.value,
                                                            })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="sac"
                                                        label="SAC"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.sac}
                                                        onChange={(e) =>
                                                            this.setState({ sac: e.target.value })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">

                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="qty"
                                                        label="Quantity"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.qty}
                                                        onChange={(e) =>
                                                            this.setState({ qty: e.target.value })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="rate"
                                                        label="Rate"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.rate}
                                                        onChange={(e) =>
                                                            this.setState({ rate: e.target.value })
                                                        }
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        style={{ width: "220px" }}
                                                        id="uom"
                                                        label="UOM"
                                                        variant="outlined"
                                                        className="mr-2 mt-2"
                                                        value={this.state.uom}
                                                        onChange={(e) =>
                                                            this.setState({ uom: e.target.value })
                                                        }
                                                        size="small"
                                                    />
                                                </div>


                                                <Button
                                                    variant="contained"
                                                    className="btn btn-primary ml-2 mt-2"
                                                    onClick={this.addItems}
                                                // disabled={
                                                //     !this.state.billTo || !this.state.itemName
                                                // }
                                                >
                                                    Add
                                                </Button>
                                            </div>



                                            <hr />

                                        </Card.Body>
                                    </Card.Header>

                                    <h5 className="text-center pb-0 mt-2">
                                        <b> TAX INVOICE</b>
                                    </h5>

                                    <Card.Body className="m-0 pt-0 mt-3">
                                        <Tbl striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Description</th>
                                                    <th>Topic Name</th>
                                                    <th>SAC</th>
                                                    <th>QTY</th>
                                                    <th>Rate</th>
                                                    <th>UOM</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            {this.state.invoiceItems.length >
                                                0 ? (
                                                <tbody>
                                                    {this.state.invoiceItems.map(
                                                        (item, id) => {
                                                            return (
                                                                <tr
                                                                    key={id}
                                                                >
                                                                    <td>
                                                                        {id + 1}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.description
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.itemName
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.sac
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.qty
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.rate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.uom
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amount
                                                                        }
                                                                    </td>
                                                                    <td className="d-print-none">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            onClick={() =>
                                                                                this.delete(id)
                                                                            }
                                                                        >

                                                                            <i class="fas fa-trash-alt"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                    <br></br>

                                                    <>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td colSpan="2">
                                                                Transportation
                                                                charges
                                                            </td>
                                                            <td></td>

                                                            <td>
                                                                {
                                                                    this.state
                                                                        .transportationCharges
                                                                }
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td colSpan="2">
                                                                Total
                                                            </td>
                                                            <td></td>

                                                            <td>
                                                                {
                                                                    this.state
                                                                        .total
                                                                }
                                                            </td>
                                                            <td></td>
                                                        </tr>

                                                        {this.state.gstType ===
                                                            1 ? (
                                                            <>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td colSpan="2">
                                                                        SGST @
                                                                    </td>

                                                                    <td>9%</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td colSpan="2">
                                                                        CGST @
                                                                    </td>

                                                                    <td>9%</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        ) : (
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td colSpan="2">
                                                                    IGST @
                                                                </td>

                                                                <td>18%</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td colSpan="4">
                                                                {" "}
                                                            </td>
                                                            <td colSpan="2">
                                                                Grand Total
                                                            </td>

                                                            <td></td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .grandTotal
                                                                }
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4">
                                                                Total in words :{" "}
                                                            </td>

                                                            <td colSpan="2">
                                                                Total (Rounded)
                                                            </td>

                                                            <td></td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .grandTotal
                                                                }
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="8">
                                                            No items added
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Tbl>
                                    </Card.Body>
                                    <Card.Body
                                        style={{ border: "1px solid black" }}
                                    >
                                        <div className="row">
                                            <div className="col-9">
                                                <h6
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    <b>
                                                        GST NO: 27AYTPS6119F1ZV
                                                    </b>
                                                    <br />
                                                    I. TAX PAN No. : AYTPS6119F
                                                    <br />
                                                    Company Bank Details:
                                                    <br />
                                                    A/C Name: Institute of
                                                    Construction Equipments and
                                                    Lifting Machines
                                                    <br />
                                                    Axis Bank, A/C no:
                                                    '917020066522350 , IFSC
                                                    Code: UTIB0000772
                                                    <br />
                                                    I/We hereby certify that
                                                    my/our Registration
                                                    Certificate under the
                                                    Maharashtra Value Added Tax
                                                    Act 2002 is in force on the
                                                    date on which the sale of
                                                    the goods specified in this
                                                    tax invoice is made by me/us
                                                    and that transaction of the
                                                    sale covered by this tax
                                                    invoice has been effected by
                                                    we/us and it shall be
                                                    counted for in the turn over
                                                    ofsales while filling of
                                                    return and the due tax, if
                                                    any payable on thesales has
                                                    been paid or shall be paid.
                                                </h6>
                                            </div>
                                            <div className="col-3">
                                                <h6
                                                    style={{
                                                        marginBottom: "7em",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    For Institute of
                                                    Construction Equipments and
                                                    Lifting Machines
                                                </h6>
                                                <h6
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    Authorised Signatory
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Body>

                                    <div style={{ border: "1px solid black" }}>
                                        <h5>Terms Of Payment :</h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            1) Payment should be made
                                            Immediately against receipt of
                                            invoice
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            2) If payment is not made within 45
                                            days GST credit will be reversed
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            3) Additional 21 % interest will be
                                            charged on non-payment of bill
                                            within 45 days
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            4) Invoice once accepted will not be
                                            entertained for any corrections
                                        </h5>
                                    </div>
                                    <div style={{ border: "1px solid black" }}>
                                        <h5 style={{ fontSize: "15px" }}>
                                            5) Customers are not entitled to
                                            claim GST credit against this bill
                                            until full amount not paid as
                                            mentioned in Total Amount column.
                                        </h5>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-primary mt-1 mr-1"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={(e) => {
                            this.updateInvoice(e);
                            this.setState({ updateModal: false });
                        }}
                    // disabled={
                    //   this.state.partyName
                    //     ? false
                    //     : true
                    // }
                    >
                        Update
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div style={{ backgroundColor: "white" }}>
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}

                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped table-sortable"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Invoice No{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Company Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Mobile No{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Total{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Date{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderUsersData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
