import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import jsPDF from "jspdf";
import CERTIFICATEEE from "../participants/assets/certificateee.jpg";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class ViewCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseId: this.props.match.params.courseId,
            participants: null,
            courseParticipants: null,
            clicked: true,
            updateModal: false,
            viewModal: false,
        };
    }

    // fetchParticipantsData() {
    //     let url = BASE_URL;
    //     const query = `SELECT * FROM participants where status = 1;`;
    //     let data = { crossDomain: true, crossOrigin: true, query: query };

    //     axios
    //         .post(url, data)
    //         .then((res) => {
    //             this.setState({ participants: res.data });
    //         })
    //         .catch((err) => {
    //         });
    // }

    fetchCourseParticipantsData() {
        let url = BASE_URL;
        const query = `select cp.id, c.id as courseId, c.name as courseName, c.refresherCourseDate, c.topic, c.auditNumber, c.startDate, c.endDate, c.location, c.type, c.message, cm.id as candidateId, cm.firstName, cm.lastName, cm.companyName, cm.designation from courseParticipants cp left join courses c on cp.courseId = c.id left join candidateMaster cm on cp.candidateId = cm.id where courseId = ${this.state.courseId} and cp.status = 1 ;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ courseParticipants: res.data });
                console.log(res.data);
            })
            .catch((err) => {});
    }

    componentDidMount() {
        // this.fetchParticipantsData();
        this.fetchCourseParticipantsData();
    }

    render() {
        const courseParticipants = this.state.courseParticipants;

        if (courseParticipants == null) {
            return null;
        }

        return courseParticipants.map((courseParticipant) => {
            if (courseParticipant.type == 1) {
                return (
                    <div>
                        {/* Content Wrapper. Contains page content */}
                        <div className="content-wrapper">
                            {/* Main content */}
                            <div class="certificate">
                                <div
                                    class="card candidate w-100 mt-2"
                                    ref={(el) => (this.printComponentRef = el)}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "2.3%",
                                            left: "33%",
                                            transform: "translate(-50%, -50%)",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        <h6 style={{ marginLeft: "18em" }}>
                                            {courseParticipant.auditNumber}
                                        </h6>
                                    </div>

                                    <h5
                                        class="text-left"
                                        style={{
                                            position: "absolute",
                                            top: "37%",
                                            left: "65%",
                                            transform: "translate(-50%, -50%)",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        Mr.{" "}
                                        {courseParticipant.firstName +
                                            " " +
                                            courseParticipant.lastName}{" "}
                                        &nbsp;&nbsp;&nbsp;(
                                        {courseParticipant.designation})
                                    </h5>

                                    <h5
                                        class="text-center"
                                        style={{
                                            position: "absolute",
                                            top: "43%",
                                            left: "65%",
                                            transform: "translate(-50%, -50%)",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        M/S {courseParticipant.companyName}
                                    </h5>

                                    <div>
                                        <h3
                                            class="text-center"
                                            style={{
                                                position: "absolute",
                                                top: "61%",
                                                left: "63%",
                                                transform:
                                                    "translate(-50%, -50%)",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            <h3>
                                                {courseParticipant.courseName}
                                            </h3>
                                        </h3>
                                    </div>

                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "71%",
                                            left: "62%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {courseParticipant.id}
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "74%",
                                            left: "62%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {courseParticipant.startDate}
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "77%",
                                            left: "62%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {courseParticipant.refresherCourseDate}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /.content-wrapper */}

                        <div></div>
                    </div>
                );
            } else {
                return (
                    <>
                        <div className="content-wrapper">
                            <div class="certificate">
                                <div
                                    class="card construction w-100 mt-2"
                                    ref={(el) => (this.printComponentRef = el)}
                                >
                                    {/* <div className="col-2">
                                <img className="side" height={150} src={CERTIFICATEEE}></img>
                            </div> */}

                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "2.3%",
                                            left: "2%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        <h6 clas>
                                            Certificate Audit No :-{" "}
                                            {courseParticipant.auditNumber}{" "}
                                        </h6>
                                    </div>
                                    <h5
                                        class="text-center"
                                        style={{
                                            position: "absolute",
                                            top: "39%",
                                            left: "45%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        Mr.{" "}
                                        {courseParticipant.firstName +
                                            " " +
                                            courseParticipant.lastName}{" "}
                                        &nbsp;(
                                        {courseParticipant.designation})
                                    </h5>

                                    <h5
                                        class="text-center"
                                        style={{
                                            position: "absolute",
                                            top: "46%",
                                            left: "52%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {courseParticipant.companyName}
                                    </h5>

                                    <div>
                                        <h4
                                            class="text-center const-part"
                                            style={{
                                                position: "absolute",
                                                top: "66%",
                                                left: "65%",
                                                transform:
                                                    "translate(-50%, -50%)",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            <h3>
                                                {courseParticipant.courseName}
                                            </h3>
                                        </h4>
                                    </div>

                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "80%",
                                            left: "62%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {courseParticipant.id}
                                    </div>
                                    <br />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "84%",
                                            left: "66%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {moment(
                                            courseParticipant.endDate
                                        ).format("DD/MM/YYYY")}
                                    </div>
                                    <br />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "88%",
                                            left: "71%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {moment(
                                            courseParticipant.refresherCourseDate
                                        ).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
        });
    }
}
