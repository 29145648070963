import React, { useContex, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { useHistory } from "react-router-dom";
// react toast
import { toast } from "react-toastify";

function Menu(props) {
    const history = useHistory();
    const [userName, setName] = useState(
        localStorage.getItem("userName") != "null"
            ? String(localStorage.getItem("userName"))
            : false
    );

    const [role, setType] = useState(
        localStorage.getItem("role") != "null"
            ? String(localStorage.getItem("role"))
            : false
    );

    const [id, setId] = useState(
        localStorage.getItem("id") != "null"
            ? String(localStorage.getItem("id"))
            : false
    );

    const logout = () => {
        history.push("/");
        toast("Logout succesufull.");
        window.localStorage.clear();
        console.clear();
    };

    return (
        <div>
            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                <div className="brand-link">
                    <Link to={`/dashboard`}>
                        <span className="brand-text font-weight-light text-white">
                            <b>ICLM</b>
                        </span>
                    </Link>
                </div>
                {/* Sidebar */}
                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image mt-1">
                            <img
                                src="dist/img/user2-160x160.jpg"
                                className="img-circle elevation-2"
                                alt="User Image"
                            />
                        </div>
                        <div className="info d-flex">
                            <a
                                className="d-flex d-auto mx-2"
                                style={{ whiteSpace: "break-spaces" }}
                            >
                                {userName}
                            </a>
                            <div class="clearfix"></div>

                            <Button
                                variant="outline-secondary"
                                onClick={logout}
                                size="sm"
                            >
                                <i class="fas fa-sign-out-alt m-0"></i>
                            </Button>
                        </div>
                    </div>

                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column nav-treeview"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                            {/* Add icons to the links using the .nav-icon class
                                with font-awesome or any other icon font library */}
                            <li className="nav-item ">
                                <Link
                                    to="/dashboard"
                                    className="nav-link active"
                                >
                                    <p>Dashboard</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link
                                    to="/loginManager"
                                    className={
                                        "nav-link active " +
                                        (role == 1 ? "d-block" : "d-none")
                                    }
                                >
                                    <p>Login Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link
                                    to="/trainers"
                                    className="nav-link active"
                                >
                                    <p>Trainer Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link
                                    to="/customers"
                                    className="nav-link active"
                                >
                                    <p>Customer Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link
                                    to="/candidates"
                                    className="nav-link active"
                                >
                                    <p>Candidate Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link to="/courses" className="nav-link active">
                                    <p>Course Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link
                                    to="/training"
                                    className="nav-link active"
                                >
                                    <p>Training Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link
                                    to="/quotations"
                                    className="nav-link active"
                                >
                                    <p>Quotation Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link to="/invoice" className="nav-link active">
                                    <p>Invoice Master</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open ">
                                <Link to="/payment" className="nav-link active">
                                    <p>Payment Master</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>
        </div>
    );
}

export default Menu;
