import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "../common/global.js";
//react toast
import { toast } from "react-toastify";
import iclmLogo from "./assets/iclmLogo.jpeg";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
import { format } from "date-fns";
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";
import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class QuotationHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quotations: null,
            quotationId: 1,
            toName: null,
            toAddress: null,
            emailId: null,
            date: format(new Date(), "dd/MM/yyyy"),
            ka: null,
            sub: null,
            quotationList: [],
            description: null,
            moduleNo: 0,
            rate: 0,
            perPersonCharge: 0,


            updateModal: false,
            viewModal: false,
        };
    }

    refresh() {
        window.location.reload(false);
    }



    updateQuote(e) {
        let url = BASE_URL;
        const query =
            'UPDATE quotationMaster SET toName="' +
            this.state.toName +
            '", toAddress="' +
            this.state.toAddress +
            '",  ka ="' +
            this.state.ka +
            '", emailId ="' +
            this.state.emailId +
            '", sub ="' +
            this.state.sub +
            '"  WHERE id=' +
            this.state.quotationId +
            " ";

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("candidate updated successfully!");
                setTimeout(this.refresh, 1000);
                this.updateBillList()
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({ name: "", address: "", email: "", mobileNo: "" });
    }

    updateBillList = () => {
        let url = BASE_URL;

        // 1.  insert into deliveryMemoList
        
            const query = `INSERT INTO quotationItems(quotationId, description, moduleNo, rate, perPersonCharge,  status) VALUES(
      ${this.state.quotationId},
      '${this.state.description}',
      '${this.state.moduleNo}',
      '${this.state.rate}',
      '${this.state.perPersonCharge}',
      1
    )`;
            console.log(query);
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "insert QuotationItems successfull, index: ",
                        
                    );
                })
                .catch((err) => {
                    console.log(
                        "failed to insert QuotationItems, error: ",
                        err
                    );
                });
        
        
    };

    fetchQuotationItemList = () => {
        let url = BASE_URL;
        const query = `SELECT * from quotationItems where quotationItems.quotationId = ${this.state.quotationId}`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("quotationItems data: ", res.data);
                this.setState({ quotationList: res.data });

                // init data table
                // this.initializeDataTable();
            })
            .catch((err) => {
                console.log("quotationItems fetch error: ", err);
            });
    };

    submitUserDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE quotationMaster SET status = -1 where id = ${id};`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                toast("quotation deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    fetchQuotationData() {
        let url = BASE_URL;
        const query = `SELECT * FROM quotationMaster where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                console.log("quotations data: ", res.data);
                this.setState({ quotations: res.data });
            })
            .catch((err) => {
                console.log("quotations data error: ", err);
            });
    }

    addItems = () => {
        // if (!this.state.toName) return;
        // let items = this.state.itemList;
        let items = this.state.quotationList;
        const ifExists = items.find(
            (item) => item.toName === this.state.toName
        );
        if (ifExists) {
            items = items.map((item) => {
                if (item.description === this.state.description) {
                    return {
                        id: this.state.id,
                        quotationId: this.state.quotationId,
                        description: this.state.description,
                        moduleNo: this.state.moduleNo,
                        rate: this.state.rate,
                        perPersonCharge: this.state.perPersonCharge,
                    };
                }
            });
        } else {
            items.push({
                id: this.state.id,
                quotationId: this.state.quotationId,
                description: this.state.description,
                moduleNo: this.state.moduleNo,
                rate: this.state.rate,
                perPersonCharge: this.state.perPersonCharge,
            });
        }
        // items.push({
        //     particular: this.state.particular,
        //     mark: this.state.mark,
        //     totalBoxes: this.state.totalBoxes,
        //     weightPerBox: this.state.weightPerBox,
        //     weight: this.state.weight,
        //     rate: this.state.rate,
        //     amount: this.state.amount,
        // });

        this.setState({ addedItems: items });
        console.log(this.state.addedItems);

        // update total & balance
        // let total = Number(this.state.total) + Number(this.state.amount);
        // let total =
        //   Number(this.state.rate * this.state.qty);
        // this.setState({ total: total }, this.calculateTaxes);
        // let balance = total - Number(this.state.paid);
        // this.setState({ balance: balance });
        // this.calculateTaxes();
    };

    delete = (index) => {
        // let itemList = this.state.itemList;
        let itemList = this.state.quotationList;

        // update total & balance
        // let total = this.state.total - itemList[index]["amount"];
        // let balance = total + Number(this.state.advance);
        // this.setState({ total: total }, this.calculateTaxes);
        // this.setState({ balance: balance });

        // remove element
        // let updatedList = itemList.filter((item, _index) => {
        //     if (index !== _index) return item;
        // });
        // this.setState({ itemList: updatedList });
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ quotationList: updatedList });
    };

    componentDidMount() {
        this.fetchQuotationData();
    }

    componentDidUpdate() {
        $(document).ready(function () {
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                    exportOptions: {
                        columns: "th:not(:last-child)",
                    },
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderUsersData() {
        const quotations = this.state.quotations;

        if (quotations == null) {
            return null;
        }

        return quotations.map((quotation) => {
            // define user type
            // let userType = ["Admin", "Engineer", "Accountant", "Partner"][
            //     user.type - 1
            // ];
            return (
                <tr>
                    <td>{quotation.id}</td>
                    <td>{quotation.toName}</td>
                    <td>{quotation.toAddress}</td>
                    <td>{quotation.emailId}</td>
                    <td>
                        {moment(quotation.lastModified).format(
                            "DD / MM / YYYY"
                        )}
                    </td>
                    {/* <td>{userType}</td> */}
                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState(
                                    {
                                        quotationId: quotation.id,
                                        toName: quotation.toName,
                                        toAddress: quotation.toAddress,
                                        emailId: quotation.emailId,
                                        date: moment(
                                            quotation.lastModified
                                        ).format("DD / MM / YYYY"),
                                        ka: quotation.ka,
                                        sub: quotation.sub,
                                        // currentcandidate_id: candidate.id,
                                        // currentcandidate_name: candidate.name,
                                        // currentcandidate_address: candidate.address,
                                        // currentcandidate_email: candidate.emailId,
                                        // currentcandidate_mobileNo: candidate.mobileNo,
                                        viewModal: true,
                                    },
                                    this.fetchQuotationItemList
                                );
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState(
                                    {
                                        quotationId: quotation.id,
                                        toName: quotation.toName,
                                        toAddress: quotation.toAddress,
                                        emailId: quotation.emailId,
                                        date: moment(
                                            quotation.lastModified
                                        ).format("DD / MM / YYYY"),
                                        ka: quotation.ka,
                                        sub: quotation.sub,
                                        // currentcandidate_id: candidate.id,
                                        // currentcandidate_name: candidate.name,
                                        // currentcandidate_address: candidate.address,
                                        // currentcandidate_email: candidate.emailId,
                                        // currentcandidate_mobileNo: candidate.mobileNo,
                                        updateModal: true,
                                    },
                                    this.fetchQuotationItemList
                                );
                            }}
                        >
                            <i class="fas fa-edit"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete user?"
                                    )
                                ) {
                                    this.submitUserDelete(quotation.id);
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        // const quotations = this.state.quotations;

        // if (quotations == null) {
        //     return null;
        // }
        // return quotations.map((quotation) => {

        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{ border: "1px solid black" }}
                        ref={(el) => (this.printComponentRef = el)}
                    >
                        <div className="row">
                            <div className="col-9">
                                <h3
                                    className="text-center"
                                    style={{ margin: "1em" }}
                                >
                                    Institute of Construction Equipment’s &
                                    Lifting Machines
                                </h3>
                                <h6
                                    className="text-center"
                                    style={{ margin: "1em" }}
                                >
                                    (Approved by Director Industrial Safety and
                                    Health, Government of Gujarat)
                                </h6>
                            </div>
                            <div className="col-3">
                                <img
                                    height={150}
                                    src={iclmLogo}
                                    style={{
                                        float: "right",
                                        marginBottom: "-1em",
                                    }}
                                ></img>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-8">
                                <p style={{ margin: "1em" }}>
                                    Date : <b>{this.state.date}</b>
                                </p>
                                <p style={{ margin: "1em" }}>
                                    Q. No: <b>{this.state.quotationId}</b>
                                </p>
                                <p style={{ margin: "1em" }}>
                                    To,
                                    <br />
                                    M/S <b>{this.state.toName}</b>
                                    <br />
                                    Add: <b>{this.state.toAddress}</b>
                                    <br />
                                    K/A: <b>{this.state.ka}</b>
                                    <br />
                                    Email Id: <b>{this.state.emailId}</b>
                                    <br />
                                    <br />
                                    Sub: <b>{this.state.sub}</b>.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p style={{ margin: "1em" }}>
                                Dear Sir, Greetings from Team ICLM. Thank you
                                for your interest in our services. ICLM is a
                                dual recognised institute by the Directorate of
                                Industrial Safety and Health (DISH)-Gujarat and
                                the Maharashtra State Board of Skill Development
                                and Examination (MSBSDE) for safety training and
                                Diploma programs. Our Motto is “विद्या लाभस्य
                                कारणम्” which means Education for Benefits. As
                                per our discussion on said subject please find
                                below mentioned price for the training programs.
                            </p>
                            <br />
                            <p style={{ margin: "1em" }}>
                                SCOPE OF TRAINING:
                                <br />
                                1. Training could be arranged for all level of
                                management.
                                <br />
                                2. Different kinds of training can be conducted
                                I.e Awareness Training; Basic Learning Training;
                                Supervisor Level Training and Capability
                                Building Training.
                                <br />
                                3. Duration of Training-
                                <br />
                                A) Awareness Training - Half Day
                                <br />
                                B) Basic Learning Training - 1 Day
                                <br />
                                C) Supervisor Level Training - 2 Day
                                <br />
                                D) Capability Building Training - 5 Day
                                <br />
                                4. Attendance sheet will be filled up along with
                                Name, Designation, Contact Number & Sign etc. of
                                the attendees.
                                <br />
                                5. Training shall not be carried out in heavy
                                wind pressure, heavy rain, thunderstorm & during
                                night.
                                <br />
                                6. Any Conditional Certificate shall not be
                                issued.
                                <br />
                                7. Certificates will be provided as per
                                Factories Act 1948 sec 111-A (ii).
                                <br />
                            </p>
                            <h5 style={{ margin: "1em" }}>
                                DESCRIPTION AND PRICE:
                            </h5>
                            <table id="quotation">
                                <thead>
                                    <tr>
                                        <th>Sr.No</th>
                                        <th>Description</th>
                                        <th>Module No</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                {this.state.quotationList.length > 0 ? (
                                    <tbody>
                                        {this.state.quotationList.map(
                                            (item, id) => {
                                                return (
                                                    // <tr key={"" + item.particularValue.title}>
                                                    //   <td>{item.particularValue.title} </td>
                                                    <tr key={id}>
                                                        <td>{id + 1}</td>
                                                        <td>
                                                            {item.description}{" "}
                                                        </td>
                                                        <td>{item.moduleNo}</td>
                                                        <td>{item.rate}</td>
                                                        <td>
                                                            {
                                                                item.perPersonCharge
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="6">No items added</td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                            <h5 style={{ margin: "1em" }}>
                                OTHER TERMS & CONDITIONS: -
                            </h5>

                            <p style={{ margin: "1em" }}>
                                1. Competency certificate shall be awarded to
                                only those participants who will attend the
                                course for the entire duration.
                                <br />
                                2. Accessories (Projector, White Board & Marker)
                                has to be provided by client in case of, “On
                                Site” training.
                                <br />
                                3. Travelling, Lodging, fooding shall be charged
                                extra for on-site training.
                                <br />
                                4. If due to any reason training scheduled is
                                not conducted/postponed then Rs. 4000/- will be
                                charged (on-site per visit).
                                <br />
                                5. Above mentioned standard rates are non-
                                negotiable.
                                <br />
                            </p>
                            <h5 style={{ margin: "1em" }}>PAYMENT TERMS-</h5>
                            <p style={{ margin: "1em" }}>
                                1. Above mentioned rates are excluding GST. GST
                                is applicable (i.e 18%)
                                <br />
                                2. Certificates will be provided only after
                                payment against proforma invoice only. (0%
                                credibility)
                                <br />
                                3. Customer will not be able to claim GST before
                                full settlement of the payment.
                                <br />
                            </p>
                            <h5 style={{ margin: "1em" }}>BANKING DETAILS-</h5>
                            <p style={{ margin: "1em" }}>
                                A/C Name: Institute of Construction Equipments
                                and Lifting Machines Axis Bank,
                                <br /> A/C no: '917020066522350 ,<br /> IFSC
                                Code: UTIB0000772
                            </p>
                            <h5 style={{ margin: "1em" }}>PRICE VALIDATION-</h5>
                            <p style={{ margin: "1em" }}>
                                • Price of the quotation is valid for a period
                                of 30days only from the date of issue of
                                quotation.
                            </p>
                            <h5 style={{ margin: "1em" }}>CERTIFICATION</h5>
                            <p style={{ margin: "1em" }}>
                                • Certificates are provided on the basis of
                                training ; not on skill.
                            </p>
                            <h5 style={{ margin: "1em" }}>VARIATIONS</h5>
                            <p style={{ margin: "1em" }}>
                                • Any kind of changes/variations made while
                                carrying out training then it would be charged
                                extra.
                                <br />
                                We are looking forward for your favorable
                                response and to get another opportunity to serve
                                & make you happy through our services.
                            </p>

                            <p style={{ margin: "1em" }}>
                                Regards
                                <br />
                                For
                                <br />
                                ICLM
                                <br />
                                _________
                                <br />
                                _________
                                <br />
                                _________
                            </p>
                            <div>
                                <p
                                    className="text-center"
                                    style={{ fontSize: "12px", color: "grey" }}
                                >
                                    Office:- 607/R Square, Veena Nagar, Opposite
                                    kamgar Hospital, LBS Road,
                                    <br /> Mulund West- 400 080
                                    <br /> Email:iclmconnect@gmail.com,
                                    www.iclm.org.in, Mobile: 09321905149
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint content={() => this.printComponentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button
                                    onClick={handlePrint}
                                    className="btn btn-primary mt-1 mr-1"
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right" }}
                                // disabled={
                                //     (this.state.partyName ||
                                //         this.state.newPartyName) &&
                                //         this.state.address
                                //         ? false
                                //         : true
                                // }
                                >
                                    Print
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
        // });
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    renderUpdateModal() {
        return (
            <Modal
                show={this.state.updateModal}
                onHide={() => {
                    this.setState({ updateModal: false });
                }}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{ border: "1px solid black" }}
                        ref={(el) => (this.printComponentRef = el)}
                    >
                        <div className="row">
                            <div className="col-9">
                                <h3
                                    className="text-center"
                                    style={{ margin: "1em" }}
                                >
                                    Institute of Construction Equipment’s &
                                    Lifting Machines
                                </h3>
                                <h6
                                    className="text-center"
                                    style={{ margin: "1em" }}
                                >
                                    (Approved by Director Industrial Safety and
                                    Health, Government of Gujarat)
                                </h6>
                            </div>
                            <div className="col-3">
                                <img
                                    height={150}
                                    src={iclmLogo}
                                    style={{
                                        float: "right",
                                        marginBottom: "-1em",
                                    }}
                                ></img>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-8">
                                <p style={{ margin: "1em" }}>
                                    Date : <b>{this.state.date}</b>
                                </p>
                                <p style={{ margin: "1em" }}>
                                    Q. No: <b>{this.state.quotationId}</b>
                                </p>
                                <p style={{ margin: "1em" }}>
                                    To,
                                    <br />
                                    M/S
                                    <input
                                        value={this.state.toName}
                                        onChange={(e) => {
                                            this.setState({
                                                toName: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    <br />
                                    Add:
                                    <input
                                        value={this.state.toAddress}
                                        onChange={(e) => {
                                            this.setState({
                                                toAddress: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    <br />
                                    K/A:
                                    <input
                                        value={this.state.ka}
                                        onChange={(e) => {
                                            this.setState({
                                                ka: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    <br />
                                    Email Id:
                                    <input
                                        value={this.state.emailId}
                                        onChange={(e) => {
                                            this.setState({
                                                emailId: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    <br />
                                    <br />
                                    Sub:
                                    <input
                                        value={this.state.sub}
                                        onChange={(e) => {
                                            this.setState({
                                                sub: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    .
                                </p>
                            </div>
                        </div>
                        <div>
                            <p style={{ margin: "1em" }}>
                                Dear Sir, Greetings from Team ICLM. Thank you
                                for your interest in our services. ICLM is a
                                dual recognised institute by the Directorate of
                                Industrial Safety and Health (DISH)-Gujarat and
                                the Maharashtra State Board of Skill Development
                                and Examination (MSBSDE) for safety training and
                                Diploma programs. Our Motto is “विद्या लाभस्य
                                कारणम्” which means Education for Benefits. As
                                per our discussion on said subject please find
                                below mentioned price for the training programs.
                            </p>
                            <br />
                            <p style={{ margin: "1em" }}>
                                SCOPE OF TRAINING:
                                <br />
                                1. Training could be arranged for all level of
                                management.
                                <br />
                                2. Different kinds of training can be conducted
                                I.e Awareness Training; Basic Learning Training;
                                Supervisor Level Training and Capability
                                Building Training.
                                <br />
                                3. Duration of Training-
                                <br />
                                A) Awareness Training - Half Day
                                <br />
                                B) Basic Learning Training - 1 Day
                                <br />
                                C) Supervisor Level Training - 2 Day
                                <br />
                                D) Capability Building Training - 5 Day
                                <br />
                                4. Attendance sheet will be filled up along with
                                Name, Designation, Contact Number & Sign etc. of
                                the attendees.
                                <br />
                                5. Training shall not be carried out in heavy
                                wind pressure, heavy rain, thunderstorm & during
                                night.
                                <br />
                                6. Any Conditional Certificate shall not be
                                issued.
                                <br />
                                7. Certificates will be provided as per
                                Factories Act 1948 sec 111-A (ii).
                                <br />
                            </p>
                            <h5 style={{ margin: "1em" }}>
                                DESCRIPTION AND PRICE:
                            </h5>
                            <div
                                className="row"
                                style={{ marginTop: ".3em", marginBottom: "2em", marginLeft: '2em' }}
                            >
                                <TextField
                                    id="description"
                                    label="description"
                                    variant="outlined"
                                    className="mr-2"
                                    value={this.state.description}
                                    onChange={(e) =>
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }
                                    size="small"
                                />

                                <TextField
                                    id="moduleNo"
                                    label="Module No"
                                    variant="outlined"
                                    className="mr-2 "
                                    value={this.state.moduleNo}
                                    onChange={(e) =>
                                        this.setState({ moduleNo: e.target.value })
                                    }
                                    size="small"
                                />
                                <TextField
                                    id="rate"
                                    label="Rate"
                                    variant="outlined"
                                    className="mr-2 "
                                    value={this.state.rate}
                                    onChange={(e) =>
                                        this.setState({ rate: e.target.value })
                                    }
                                    size="small"
                                />
                                <TextField
                                    id="perPersonCharge"
                                    label="Amount"
                                    variant="outlined"
                                    className="mr-2 "
                                    value={this.state.perPersonCharge}
                                    onChange={(e) =>
                                        this.setState({
                                            perPersonCharge: e.target.value,
                                        })
                                    }
                                    size="small"
                                />

                                <Button
                                    variant="contained"
                                    className="btn btn-primary"
                                    onClick={this.addItems}
                                // disabled={
                                //   !this.state.billTo || !this.state.itemname
                                // }
                                >
                                    Add
                                </Button>

                            </div>
                            <table id="quotation">
                                <thead>
                                    <tr>
                                        <th>Sr.No</th>
                                        <th>Description</th>
                                        <th>Module No</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {this.state.quotationList.length > 0 ? (
                                    <tbody>
                                        {this.state.quotationList.map(
                                            (item, id) => {
                                                return (
                                                    // <tr key={"" + item.particularValue.title}>
                                                    //   <td>{item.particularValue.title} </td>
                                                    <tr key={id}>
                                                        <td>{id + 1}</td>
                                                        <td>
                                                            {item.description}
                                                        </td>
                                                        <td>
                                                            {item.moduleNo}
                                                        </td>
                                                        <td>
                                                            {item.rate}
                                                        </td>
                                                        <td>
                                                            {item.perPersonCharge}
                                                        </td>
                                                        <td className="d-print-none">
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={() =>
                                                                    this.delete(id)
                                                                }
                                                            >

                                                                <i class="fas fa-trash-alt"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="6">No items added</td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                            <h5 style={{ margin: "1em" }}>
                                OTHER TERMS & CONDITIONS: -
                            </h5>

                            <p style={{ margin: "1em" }}>
                                1. Competency certificate shall be awarded to
                                only those participants who will attend the
                                course for the entire duration.
                                <br />
                                2. Accessories (Projector, White Board & Marker)
                                has to be provided by client in case of, “On
                                Site” training.
                                <br />
                                3. Travelling, Lodging, fooding shall be charged
                                extra for on-site training.
                                <br />
                                4. If due to any reason training scheduled is
                                not conducted/postponed then Rs. 4000/- will be
                                charged (on-site per visit).
                                <br />
                                5. Above mentioned standard rates are non-
                                negotiable.
                                <br />
                            </p>
                            <h5 style={{ margin: "1em" }}>PAYMENT TERMS-</h5>
                            <p style={{ margin: "1em" }}>
                                1. Above mentioned rates are excluding GST. GST
                                is applicable (i.e 18%)
                                <br />
                                2. Certificates will be provided only after
                                payment against proforma invoice only. (0%
                                credibility)
                                <br />
                                3. Customer will not be able to claim GST before
                                full settlement of the payment.
                                <br />
                            </p>
                            <h5 style={{ margin: "1em" }}>BANKING DETAILS-</h5>
                            <p style={{ margin: "1em" }}>
                                A/C Name: Institute of Construction Equipments
                                and Lifting Machines Axis Bank,
                                <br /> A/C no: '917020066522350 ,<br /> IFSC
                                Code: UTIB0000772
                            </p>
                            <h5 style={{ margin: "1em" }}>PRICE VALIDATION-</h5>
                            <p style={{ margin: "1em" }}>
                                • Price of the quotation is valid for a period
                                of 30days only from the date of issue of
                                quotation.
                            </p>
                            <h5 style={{ margin: "1em" }}>CERTIFICATION</h5>
                            <p style={{ margin: "1em" }}>
                                • Certificates are provided on the basis of
                                training ; not on skill.
                            </p>
                            <h5 style={{ margin: "1em" }}>VARIATIONS</h5>
                            <p style={{ margin: "1em" }}>
                                • Any kind of changes/variations made while
                                carrying out training then it would be charged
                                extra.
                                <br />
                                We are looking forward for your favorable
                                response and to get another opportunity to serve
                                & make you happy through our services.
                            </p>

                            <p style={{ margin: "1em" }}>
                                Regards
                                <br />
                                For
                                <br />
                                ICLM
                                <br />
                                _________
                                <br />
                                _________
                                <br />
                                _________
                            </p>
                            <div>
                                <p
                                    className="text-center"
                                    style={{ fontSize: "12px", color: "grey" }}
                                >
                                    Office:- 607/R Square, Veena Nagar, Opposite
                                    kamgar Hospital, LBS Road,
                                    <br /> Mulund West- 400 080
                                    <br /> Email:iclmconnect@gmail.com,
                                    www.iclm.org.in, Mobile: 09321905149
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-primary mt-1 mr-1"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={(e) => {
                            this.updateQuote(e);
                            this.setState({ updateModal: false });
                        }}
                    // disabled={
                    //   this.state.partyName
                    //     ? false
                    //     : true
                    // }
                    >
                        Update
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ updateModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //modal for update user ends here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div style={{ backgroundColor: "white" }}>
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}

                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped table-sortable"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Address{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Email{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Date{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderUsersData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderUpdateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
