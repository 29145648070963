import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/navbar/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/loginManager/Login";
import ProgressTracker from "./components/progressTracker/ProgressTracker";
import Trainers from "./components/trainers/trainersManager";
import Customer from "./components/customer/customerManager";
import courses from "./components/courses/course";
import TrainingMaster from "./components/trainingMaster/trianingMaster";
import Participant from "./components/participants/participant";
import Invoice from "./components/invoices/invoice";
import Payment from "./components/paymentMaste/payment";
import Quotation from "./components/quotrationsMaster/quotation";
import Candidate from "./components/candidates/candidate";
import ViewCertificate from "./components/viewCertificates/viewcertificate";

import "./App.css";
import LoginForm from "./components/login/Login";
import { Route, Switch, HashRouter } from "react-router-dom";

function App() {
    const [name, setName] = useState(
        localStorage.getItem("name") != "null"
            ? String(localStorage.getItem("name"))
            : false
    );

    const [type, setType] = useState(
        localStorage.getItem("type") != "null"
            ? parseInt(localStorage.getItem("type"))
            : false
    );
    const [id, setId] = useState(
        localStorage.getItem("id") != "null"
            ? parseInt(localStorage.getItem("id"))
            : 0
    );

    return (
        <div className="wrapper">
            <HashRouter>
                <Switch>
                    <Route exact path="/" component={LoginForm} />
                    <div>
                        <Navbar />
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/loginManager" component={Login} />

                        <Route path="/trainers" component={Trainers} />
                        <Route path="/customers" component={Customer} />
                        <Route path="/courses" component={courses} />
                        <Route path="/training" component={TrainingMaster} />
                        <Route
                            path="/participants/:currentCourse_id/"
                            component={Participant}
                        />

                        <Route path="/invoice" component={Invoice} />
                        <Route path="/payment" component={Payment} />
                        <Route path="/quotations" component={Quotation} />
                        <Route path="/candidates" component={Candidate} />
                        <Route
                            path="/viewCertificates/:courseId/"
                            component={ViewCertificate}
                        />

                        <Route
                            path="/machines/progress-tracker/:machineId/:userId"
                            component={ProgressTracker}
                        />
                    </div>
                </Switch>
            </HashRouter>
            <ToastContainer />
        </div>
    );
}

export default App;
