import React, { Component } from "react";
// modal,Button via react bootstrap
import { Modal, Button } from "react-bootstrap";
//API handling components
import { BASE_URL } from "./../common/global.js";
//react toast
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import jsPDF from "jspdf";
import { TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import iclmLogo from "../quotrationsMaster/assets/iclmLogo.jpeg";

import { AuthContext } from "../../Context/authContext";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// Axios for HTTP req
const axios = require("axios");

export default class Participant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseId: this.props.match.params.currentCourse_id,
            participants: [],
            candidates: [],
            clicked: true,
            participantId: 0,
            participantName: null,
            status: 1,
            currentParticipant_id: null,
            currentParticipant_courseName: null,
            currentParticipant_participantName: null,
            updateModal: false,
            viewModal: false,
            certificateModal: false,
            currentParticipant_issueDate: null,
            currentParticipant_refreshDate: null,
            currentParticipant_auditNumber: null,
            currentParticipant_type: 1,
            currentParticipant_topic: null,
            currentParticipant_companyName: null,
            currrentParticipant_designation: null,
            // currentParticipant_type: 0,
        };
    }

    // changeText = (text) => {

    //     this.setState({ text });
    // }

    refresh() {
        window.location.reload(false);
    }

    generatePDF = () => {
        var doc = new jsPDF("p", "pt");

        doc.text(20, 20, "This is the first title.");
        doc.addFont("helvetica", "normal");
        doc.text(20, 60, "This is the second title.");
        doc.text(20, 100, "This is the thrid title.");

        doc.save("certificates.pdf");
    };

    onSubmit(e) {
        let url = BASE_URL;
        const query =
            'INSERT INTO courseParticipants (candidateId ,courseId) values("' +
            this.state.participantId +
            '","' +
            this.state.courseId +
            '") ';
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("new participant added!");
                setTimeout(this.refresh, 1000);
                console.log(res.data);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });

        this.setState({ participantId: "" });
    }

    submitParticipantDelete(id) {
        let url = BASE_URL;
        const query = `UPDATE courseParticipants SET status = -1 where id = ${id};`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                toast("participant deleted successfully!");
                setTimeout(this.refresh, 1000);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });

        this.setState({ name: "", participantName: "" });
    }

    fetchParticipantsData() {
        let url = BASE_URL;
        // const query = `SELECT courseParticipants.candidateId AS candidateId,courseParticipants.candidateId AS candidateId,  courses.name AS course_name FROM courseParticipants JOIN courses ON courseParticipants.id=courses.id where courseParticipants.courseId = ${this.state.courseId} and courseParticipants.status = 1;`;
        const query = `select cp.id, c.id as courseId, c.name as courseName, c.issueDate, c.refresherCourseDate, c.topic, c.auditNumber, c.startDate, c.endDate, c.location, c.type, c.message, cm.id as candidateId, cm.firstName, cm.lastName, cm.companyName, cm.designation from courseParticipants cp left join courses c on cp.courseId = c.id left join candidateMaster cm on cp.candidateId = cm.id where courseId = ${this.state.courseId} and cp.status = 1;`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ participants: res.data });
                console.log("fetch", res.data);
            })
            .catch((err) => {});
    }

    fetchCandidatesData() {
        let url = BASE_URL;
        const query = `SELECT id, firstName, lastName FROM candidateMaster where status = 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };

        axios
            .post(url, data)
            .then((res) => {
                this.setState({ candidates: res.data });
                console.log("candidate data", res.data);
            })
            .catch((err) => {});
    }

    updatePresenty(id) {
        var _participant = this.state.participants;
        var status = 0;

        let url = BASE_URL;
        const query = `update courseParticipants set isPresent = ${this.state.status} where id= ${id};`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);

                for (var i = 0; i < _participant.length; i++) {
                    if (_participant[i].id == id) {
                        _participant[i].isPresent =
                            _participant[i].isPresent == 0
                                ? 1
                                : _participant[i].isPresent == 1
                                ? 2
                                : 1;
                        status = _participant[i].isPresent;
                    }
                }
                // update courseParticipants set isPresent = status where id=
                this.setState({ participants: _participant });
            })
            .catch((err) => {});
    }

    componentDidMount() {
        this.fetchParticipantsData();
        this.fetchCandidatesData();
    }

    componentDidUpdate() {
        $(document).ready(function () {
            $(".button").on("click", function () {
                $(this).html(
                    $(this).html() == "follow" ? "followed" : "follow"
                );
            });
            $("#example1")
                .DataTable({
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["copy", "csv", "excel", "print"],
                })
                .buttons()
                .container()
                .appendTo("#example1_wrapper .col-md-6:eq(0)");
        });
    }

    renderCoursesData() {
        const { text } = this.state; //destucture state

        const participants = this.state.participants;

        if (participants == null) {
            return null;
        }

        return participants.map((participant) => {
            // define user type
            // let courseType = ["1", "2", "3", "4"][
            //     course.type - 1
            // ];
            // let color = this.state.clicked === "Present" ? 'Green' : '#c82333';

            return (
                <tr>
                    <td>{participant.id}</td>
                    <td>
                        {participant.firstName + " " + participant.lastName}
                    </td>
                    <td>{participant.courseName}</td>
                    <td>
                        <Button
                            style={{
                                backgroundColor:
                                    participant.isPresent == 1
                                        ? "Green"
                                        : participant.isPresent == 2
                                        ? "#dc3545"
                                        : "grey",
                                border: "none",
                            }}
                            onClick={(e) => this.updatePresenty(participant.id)}
                        >
                            {participant.isPresent == 1
                                ? "Present"
                                : participant.isPresent == 2
                                ? "Absent"
                                : "Pending"}
                        </Button>
                    </td>

                    <td className="">
                        <button
                            class="btn btn-primary btn-sm mx-1"
                            onClick={() => {
                                this.setState({
                                    currentParticipant_id: participant.id,
                                    currentParticipant_participantName:
                                        participant.firstName +
                                        " " +
                                        participant.lastName,
                                    currentParticipant_courseName:
                                        participant.courseName,
                                    currentParticipant_auditNumber:
                                        participant.auditNumber,
                                    currentParticipant_issueDate: moment(
                                        participant.issueDate
                                    ).format("DD/MM/YYYY"),
                                    currentParticipant_refreshDate: moment(
                                        participant.refresherCourseDate
                                    ).format("DD/MM/YYYY"),
                                    currentParticipant_topic: participant.topic,
                                    currentParticipant_companyName:
                                        participant.companyName,
                                    currrentParticipant_designation:
                                        participant.designation,
                                });

                                if (participant.type == 1) {
                                    this.setState({ viewModal: true });
                                } else {
                                    this.setState({ certificateModal: true });
                                }
                            }}
                        >
                            <i class="fas fa-eye"></i>
                        </button>

                        <button
                            class="btn btn-danger btn-sm mx-1"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Really want to delete trainer?"
                                    )
                                ) {
                                    this.submitParticipantDelete(
                                        participant.id
                                    );
                                }
                            }}
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    //   Render view modal starts here (view button)

    renderViewModal() {
        return (
            <Modal
                show={this.state.viewModal}
                onHide={() => {
                    this.setState({ viewModal: false });
                }}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Certificate:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="certificate">
                        <div
                            class="card candidate w-100 mt-2"
                            ref={(el) => (this.printComponentRef = el)}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: "2.3%",
                                    left: "33%",
                                    transform: "translate(-50%, -50%)",
                                    textTransform: "uppercase",
                                }}
                            >
                                <h6 style={{ marginLeft: "18em" }}>
                                    {this.state.currentParticipant_auditNumber}
                                </h6>
                            </div>

                            <h5
                                class="text-left"
                                style={{
                                    position: "absolute",
                                    top: "37%",
                                    left: "65%",
                                    transform: "translate(-50%, -50%)",
                                    textTransform: "uppercase",
                                }}
                            >
                                Mr.{" "}
                                {this.state.currentParticipant_participantName}{" "}
                                &nbsp;(
                                {this.state.currrentParticipant_designation})
                            </h5>

                            <h5
                                class="text-center"
                                style={{
                                    position: "absolute",
                                    top: "43%",
                                    left: "65%",
                                    transform: "translate(-50%, -50%)",
                                    textTransform: "uppercase",
                                }}
                            >
                                M/S {this.state.currentParticipant_companyName}
                            </h5>

                            <div>
                                <h3
                                    class="text-center"
                                    style={{
                                        position: "absolute",
                                        top: "61%",
                                        left: "63%",
                                        transform: "translate(-50%, -50%)",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <h3>
                                        {
                                            this.state
                                                .currentParticipant_courseName
                                        }
                                    </h3>
                                </h3>
                            </div>

                            <div
                                style={{
                                    position: "absolute",
                                    top: "71%",
                                    left: "62%",
                                    textTransform: "uppercase",
                                }}
                            >
                                {this.state.currentParticipant_id}
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    top: "74%",
                                    left: "62%",
                                    textTransform: "uppercase",
                                }}
                            >
                                {this.state.currentParticipant_refreshDate}
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    top: "77%",
                                    left: "62%",
                                    textTransform: "uppercase",
                                }}
                            >
                                {this.state.currentParticipant_refreshDate}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint content={() => this.printComponentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button
                                    onClick={handlePrint}
                                    className="btn btn-primary mt-1 mr-1"
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right" }}
                                    // disabled={
                                    //     (this.state.partyName ||
                                    //         this.state.newPartyName) &&
                                    //         this.state.address
                                    //         ? false
                                    //         : true
                                    // }
                                >
                                    Print
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ viewModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderCertificateModal() {
        return (
            <Modal
                show={this.state.certificateModal}
                onHide={() => {
                    this.setState({ certificateModal: false });
                }}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Certificate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="certificate">
                        <div
                            class="card construction w-100 mt-2"
                            ref={(el) => (this.printComponentRef = el)}
                        >
                            {/* <div className="col-2">
                                <img className="side" height={150} src={CERTIFICATEEE}></img>
                            </div> */}

                            <h5
                                class="text-center"
                                style={{
                                    position: "absolute",
                                    top: "39%",
                                    left: "45%",
                                    textTransform: "uppercase",
                                }}
                            >
                                Mr.{" "}
                                {this.state.currentParticipant_participantName}
                                &nbsp; (
                                {this.state.currrentParticipant_designation})
                            </h5>

                            <h5
                                class="text-center"
                                style={{
                                    position: "absolute",
                                    top: "46%",
                                    left: "52%",
                                    textTransform: "uppercase",
                                }}
                            >
                                {this.state.currentParticipant_companyName}
                            </h5>

                            <div>
                                <h4
                                    class="text-center const-part"
                                    style={{
                                        position: "absolute",
                                        top: "66%",
                                        left: "65%",
                                        transform: "translate(-50%, -50%)",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <h3>
                                        {
                                            this.state
                                                .currentParticipant_courseName
                                        }
                                    </h3>
                                </h4>
                            </div>

                            <div
                                style={{
                                    position: "absolute",
                                    top: "80%",
                                    left: "62%",
                                    textTransform: "uppercase",
                                }}
                            >
                                {this.state.currentParticipant_id}
                            </div>
                            <br />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "84%",
                                    left: "66%",
                                    textTransform: "uppercase",
                                }}
                            >
                                {this.state.currentParticipant_issueDate}
                            </div>
                            <br />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "88%",
                                    left: "71%",
                                    textTransform: "uppercase",
                                }}
                            >
                                {this.state.currentParticipant_refreshDate}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint content={() => this.printComponentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button
                                    onClick={handlePrint}
                                    className="btn btn-primary mt-1 mr-1"
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right" }}
                                    // disabled={
                                    //     (this.state.partyName ||
                                    //         this.state.newPartyName) &&
                                    //         this.state.address
                                    //         ? false
                                    //         : true
                                    // }
                                >
                                    Print
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.setState({ certificateModal: false });
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //   Render view modal ends here (view button)

    // modal for update user starts here

    render() {
        return (
            <div>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div class="mt-2">
                                        {/* add new user modal */}
                                        <div className="add">
                                            <button
                                                type="button"
                                                class=" new btn btn-primary btn-sm mb-2 mr-2"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                data-whatever="@mdo"
                                            >
                                                <span>
                                                    <i class="fas fa-user-plus"></i>
                                                </span>
                                                &nbsp;Add New Participants
                                            </button>
                                            <Link
                                                to={`/viewCertificates/${this.state.courseId}`}
                                            >
                                                <button
                                                    type="button"
                                                    class=" new btn btn-primary btn-sm mb-2"
                                                >
                                                    <span>
                                                        <i class="fas fa-certificate"></i>
                                                    </span>
                                                    &nbsp;View Certificates
                                                </button>
                                            </Link>
                                            <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex={-1}
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5
                                                                className="modal-title"
                                                                id="exampleModalLabel"
                                                            >
                                                                Add New
                                                                Participant
                                                                {/*  */}
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    ×
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <FormControl
                                                                    style={{
                                                                        minWidth:
                                                                            "250px",
                                                                    }}
                                                                    className="mr-2 mb-2 smt-0"
                                                                >
                                                                    <Autocomplete
                                                                        id="free-solo-demo"
                                                                        freeSolo
                                                                        options={
                                                                            this
                                                                                .state
                                                                                .candidates !=
                                                                            null
                                                                                ? this.state.candidates.map(
                                                                                      (
                                                                                          item
                                                                                      ) =>
                                                                                          item.id +
                                                                                          ", " +
                                                                                          item.firstName +
                                                                                          " " +
                                                                                          item.lastName
                                                                                  )
                                                                                : []
                                                                        }
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <TextField
                                                                                {...params}
                                                                                // label="party name"
                                                                                className="form-control"
                                                                                label="Participant name"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .participantName
                                                                                }
                                                                                style={{
                                                                                    width: "450px",
                                                                                }}
                                                                            />
                                                                        )}
                                                                        onChange={(
                                                                            event,
                                                                            value
                                                                        ) => {
                                                                            value =
                                                                                value.split(
                                                                                    ", "
                                                                                );
                                                                            console.log(
                                                                                value
                                                                            );
                                                                            if (
                                                                                value !=
                                                                                    null &&
                                                                                value.length >=
                                                                                    2
                                                                            ) {
                                                                                this.setState(
                                                                                    {
                                                                                        participantId:
                                                                                            Number(
                                                                                                value[0]
                                                                                            ),
                                                                                    }
                                                                                );
                                                                                this.setState(
                                                                                    {
                                                                                        participantName:
                                                                                            String(
                                                                                                value[1]
                                                                                            ),
                                                                                    }
                                                                                );
                                                                            } else {
                                                                                this.setState(
                                                                                    {
                                                                                        participantId:
                                                                                            null,
                                                                                        participantName:
                                                                                            "",
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.onSubmit(
                                                                        e
                                                                    );
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for update  starts here*/}

                                        {/* modal for update ends here */}
                                        <table
                                            id="example1"
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        id{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Participant Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Course Name{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        Status{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                    <th>
                                                        operation{" "}
                                                        <i class="fas fa-sort"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderCoursesData()}
                                                <div
                                                    className="modal fade"
                                                    id="update"
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div
                                                        className="modal-dialog"
                                                        role="document"
                                                    >
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalLabel"
                                                                >
                                                                    New message
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        ×
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="recipient-name"
                                                                            className="col-form-label"
                                                                        >
                                                                            Recipient:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="recipient-name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label
                                                                            htmlFor="message-text"
                                                                            className="col-form-label"
                                                                        >
                                                                            Message:
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="message-text"
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    data-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary"
                                                                >
                                                                    update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                    {this.renderViewModal()}
                    {this.renderCertificateModal()}
                </div>
                {/* /.content-wrapper */}
            </div>
        );
    }
}
